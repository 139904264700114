import { useMemo } from "react";
import { useAppSelector } from "../../../app/app-hooks";
import {
  selectProjectById,
  selectSelectedProjectId,
} from "../../../features/api/slices/projects-slice";
import ManagerContactModal, {
  ManagerContactModalProps,
} from "../../program/modal/manager-contact-modal";

interface ProjectManagerContactModalProps
  extends Omit<ManagerContactModalProps, "adminAuthID"> {}

const ProjectManagerContactModal = (props: ProjectManagerContactModalProps) => {
  const projectId = useAppSelector(selectSelectedProjectId) || "";
  const project = useAppSelector((state) =>
    selectProjectById(state, projectId)
  );
  const adminId = useMemo(() => project?.account_manager || "", [project]);

  return <ManagerContactModal adminAuthID={adminId} {...props} />;
};

export default ProjectManagerContactModal;

