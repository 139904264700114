import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import ProviderProfile from "@hellodarwin/core/lib/components/provider/profile";
import {
  ProfileSectionContainer,
  ProviderProfileContainer,
} from "@hellodarwin/core/lib/components/provider/profile/styles";
import { Portfolio } from "@hellodarwin/core/lib/features/entities";
import ProfileBlocks from "@hellodarwin/core/lib/features/enums/profile-blocks";
import ProjectType from "@hellodarwin/core/lib/features/enums/project-type";
import { getShortId } from "@hellodarwin/core/lib/features/helpers";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Skeleton from "antd/es/skeleton";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/app-hooks";
import { RootState } from "../../../../app/index";
import ProviderActions from "../../../../components/provider/provider-actions";
import {
  openMatchModal,
  setActiveTab,
} from "../../../../features/api/slices/global-slice";
import {
  fetchMatch,
  selectHasWinner,
  selectMatchesLoading,
  selectProjectMatchById,
} from "../../../../features/api/slices/matches-slice";
import {
  fetchProject,
  selectProjectById,
  selectProjectsLoading,
  setSelectedProjectId,
} from "../../../../features/api/slices/projects-slice";
import {
  fetchWonProjects,
  selectWonProjectsByProviderId,
} from "../../../../features/api/slices/won-projects-slice";
import { useClientApi } from "../../../../features/api/use-client-api";

const ClientSingleProviderPage = () => {
  const api = useClientApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();
  const { t } = useTranslation();
  const { projectId, matchId } = useParams<{
    projectId: string;
    matchId: string;
  }>();

  const project = useAppSelector((state) =>
    selectProjectById(state, projectId || "")
  );

  const selectedMatch = useAppSelector((state) =>
    selectProjectMatchById(state, matchId || "")
  );

  const hasWinner = useAppSelector(selectHasWinner);

  const loadingProject = useAppSelector(selectProjectsLoading);
  const loadingMatches = useAppSelector(selectMatchesLoading);

  const returnToProject = () => {
    navigate(`/projects/${projectId}/candidates`);
    dispatch(setActiveTab("candidates"));
  };

  const projectsWon = useAppSelector((state: RootState) =>
    selectWonProjectsByProviderId(state, selectedMatch?.provider_id || "")
  );

  const [sortedPortfolios, setSortedPortfolios] = useState<Portfolio[]>([]);

  useEffect(() => {
    if (project?.service && selectedMatch?.provider?.portfolios) {
      var newSortedPortfolios: Set<Portfolio> = new Set<Portfolio>();
      var remainingPortfolios: Set<Portfolio> = new Set<Portfolio>();
      var servicePortfolio: Set<Portfolio> = new Set<Portfolio>();
      for (let portfolio of selectedMatch?.provider?.portfolios) {
        if (portfolio.service === project?.service) {
          servicePortfolio.add(portfolio);
        } else if (project?.tags) {
          for (let speciality of project?.tags) {
            var found = portfolio.tags
              ? portfolio.tags.find((x) => x === speciality.tag)
              : false;
            if (found) {
              newSortedPortfolios.add(portfolio);
            } else {
              remainingPortfolios.add(portfolio);
            }
          }
        } else {
          remainingPortfolios.add(portfolio);
        }
      }

      servicePortfolio.forEach((x) => newSortedPortfolios.add(x));
      remainingPortfolios.forEach((x) => newSortedPortfolios.add(x));

      setSortedPortfolios(Array.from(newSortedPortfolios.values()));
    } else {
      setSortedPortfolios(selectedMatch?.provider?.portfolios || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatch]);

  useEffect(() => {
    if (!!projectId && project?.rfp_id !== projectId) {
      dispatch(fetchProject({ api, projectId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (project) {
      dispatch(setSelectedProjectId(project.rfp_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (matchId) {
      dispatch(fetchMatch({ api, matchId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchId]);

  useEffect(() => {
    if (!selectedMatch?.provider_id) return;
    dispatch(fetchWonProjects({ api, providerId: selectedMatch.provider_id }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatch]);

  const sections = useMemo(() => {
    return [
      ProfileBlocks.FastInfos,
      ProfileBlocks.Presentation,
      ProfileBlocks.Portfolios,
      ProfileBlocks.About,
      ProfileBlocks.Reviews,
      ProfileBlocks.Project,
      ProfileBlocks.Team,
      ProfileBlocks.Expertise,
      ProfileBlocks.Contact,
    ];
  }, []);

  const LoadingState = () => {
    return (
      <ProviderProfileContainer
        style={{
          height: "90vh",
        }}
      >
        <ProfileSectionContainer
          style={{ borderRadius: "11px 11px 0 0", overflow: "visible" }}
        >
          <Skeleton
            paragraph={{ rows: 5 }}
            active
            style={{ height: 400 }}
          ></Skeleton>
        </ProfileSectionContainer>
        <ProfileSectionContainer style={{ overflow: "visible" }}>
          <Skeleton
            paragraph={{ rows: 5 }}
            active
            style={{ height: 250 }}
          ></Skeleton>
        </ProfileSectionContainer>
        <ProfileSectionContainer style={{ overflow: "visible" }}>
          <Skeleton
            paragraph={{ rows: 5 }}
            active
            style={{ height: 250 }}
          ></Skeleton>
        </ProfileSectionContainer>
        <ProfileSectionContainer style={{ overflow: "visible" }}>
          <Skeleton paragraph={{ rows: 5 }} style={{ height: 250 }}></Skeleton>
        </ProfileSectionContainer>
      </ProviderProfileContainer>
    );
  };

  const handleLeaveAReview = () => {
    dispatch(
      openMatchModal({
        isVisible: true,
        type: "leaveReview",
        matchId: selectedMatch?.match_id || "",
        source: "Client",
      })
    );
  };

  return (
    <>
      <PageLayout
        app="client"
        title={selectedMatch?.provider?.name}
        className="project-providers-page"
        handleBack={returnToProject}
        breadcrumbs={[
          {
            breadcrumbName: t("navigation.home"),
            path: "/",
          },
          {
            breadcrumbName: t("project|yourProjects"),
            path: "/projects",
          },
          {
            breadcrumbName: !!project?.title
              ? project?.title
              : project?.service
                ? project.service
                : `${t("project|project")} ${getShortId(
                    project?.rfp_id || ""
                  )}`,
            onClick: returnToProject,
          },
          {
            breadcrumbName: selectedMatch?.provider?.name,
          },
        ]}
      >
        {loadingProject ||
        !project ||
        loadingMatches ||
        !selectedMatch?.provider ? (
          <LoadingState />
        ) : (
          <ProviderProfile
            key={selectedMatch.match_id}
            match={selectedMatch}
            project={project}
            provider={selectedMatch?.provider}
            handleLeaveAReview={handleLeaveAReview}
            providerActions={
              <ProviderActions
                match={selectedMatch}
                hasWinner={hasWinner}
                onProfile
                isClassic={project.type === ProjectType.Classic}
              />
            }
            providerPrimaryActions={
              <ProviderActions
                match={selectedMatch}
                hasWinner={hasWinner}
                isPrimary
                isBigger
              />
            }
            teamMembers={selectedMatch?.provider?.team_members || []}
            portfolios={sortedPortfolios}
            reviews={selectedMatch?.provider?.reviews || []}
            sections={sections}
            projects={{
              isLoading: loadingProject,
              projects: projectsWon,
            }}
            locale={selectedLocale}
            state="client"
          />
        )}
      </PageLayout>
    </>
  );
};

export default ClientSingleProviderPage;

