import styled from "../../../plugins/styled";
import Modal from "../../common/hd-modal";
import { ModalContent } from "../../common/hd-modal/styles";

export const GrantInquiryModalStyled = styled(Modal)`
  max-width: 100vw !important;
  width: 100% !important;
  max-height: 100vh;
  height: 100%;
  padding: 0;
  margin: 0 !important;
  top: 0;
  left: 0;
  position: relative;
  ${ModalContent} {
    margin: auto;

    width: 1080px !important;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
      width: 100vw !important;
      max-width: 100vw !important;
    }
  }
  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

