import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FundingExplorerFilterProps } from ".";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../../common/button";
import HdTag from "../../../common/hd-tag";
import {
  FundingExplorerTagBarContainer,
  FundingExplorerTagBarStyled,
} from "./styles";
import { FundingExplorerFilterValues } from "./types";
import useTagsInformations from "./utils/use-tag-informations";

const FilterTagBar: React.FC<FundingExplorerFilterProps> = (props) => {
  const theme = useTheme();

  const { filterform, filterValues } = props;

  const [isOpen, setIsOpen] = useState(false);
  const handleTagBarOpen = () => setIsOpen(!isOpen);

  const removeTag = (
    newValue: string,
    type: keyof FundingExplorerFilterValues
  ) => {
    const value = filterValues[type];
    if (Array.isArray(value)) {
      filterform.setFieldValue(
        type,
        (filterValues[type] as string[])?.filter(
          (selectedValue: string) => selectedValue !== newValue
        )
      );
    } else if (value === newValue) {
      filterform.setFieldValue(type, undefined);
    } else {
      filterform.setFieldValue(type, newValue);
    }
  };

  const [scrollHeight, setHeight] = useState(0);

  const ref = useRef<HTMLDivElement>(null);
  const updateHeight = () => {
    if (ref.current) {
      setHeight(ref.current.scrollHeight);
    }
  };

  useEffect(() => {
    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const { allTags, allTagsInformations } = useTagsInformations(props);

  useEffect(() => {
    updateHeight();
  }, [allTags]);

  const withDrawer = useMemo(() => scrollHeight > 70, [scrollHeight]);

  useEffect(() => {
    if (!withDrawer) {
      setIsOpen(false);
    }
  }, [withDrawer]);

  if (!allTags?.length) return <></>;

  return (
    <FundingExplorerTagBarContainer
      ref={ref}
      $isOpen={isOpen}
      $withDrawer={withDrawer}
    >
      <FundingExplorerTagBarStyled $isOpen={isOpen} $withDrawer={withDrawer}>
        {React.Children.toArray(
          allTags?.map((value) => (
            <HdTag
              text={allTagsInformations[value]?.label}
              hoverable
              color={allTagsInformations[value]?.tagColor}
              onClose={() => {
                removeTag(value, allTagsInformations[value]?.type);
              }}
            />
          ))
        )}
      </FundingExplorerTagBarStyled>
      {withDrawer && (
        <Button
          onClick={handleTagBarOpen}
          defaultStyle={theme.colors.primary}
          style={{ position: "absolute", bottom: 8, right: 8, zIndex: 100 }}
          headingIcon={<TabArrow size={16} down={!isOpen} />}
          size="square"
          transparent
        />
      )}
    </FundingExplorerTagBarContainer>
  );
};
export default FilterTagBar;

