import { useState } from "react";
import { ProjectUpdateFunctions } from "../../../../../features/entities/project-entities";
import { ReviewFormSteps } from "../../../../../features/entities/project-status-flow-entities";
import { Provider } from "../../../../../features/entities/providers-entities";
import { ReviewRequest } from "../../../../../features/entities/reviews-entities";
import ReviewForm from "../../../../reviews/review/review-form";

type QuickReviewStepProps = {
  handleClose: () => void;
  editionFunctions: ProjectUpdateFunctions;
  provider: Provider;
  projectId: string;
  matchId: string;
  companyTags?: string[];
};

const QuickReviewStep = ({
  handleClose,
  editionFunctions,
  provider,
  projectId,
  matchId,
  companyTags,
}: QuickReviewStepProps) => {
  const handleReviewProvider = async (reviewRequest: ReviewRequest) => {
    if (!!editionFunctions.review) {
      editionFunctions.review(
        projectId,
        provider.provider_id,
        reviewRequest,
        matchId
      );
    }
  };

  const [reviewStep, setReviewStep] = useState<ReviewFormSteps>(
    ReviewFormSteps.Experience
  );
  return (
    <ReviewForm
      handleClose={handleClose}
      providerId={provider.provider_id}
      rfpId={projectId}
      providerName={provider.name}
      reviewProvider={handleReviewProvider}
      setReviewStep={setReviewStep}
      reviewStep={reviewStep}
      tags={provider.tags}
      companyTags={companyTags ?? []}
    />
  );
};

export default QuickReviewStep;

