import Grants from "@hellodarwin/icons/dist/icons/Grants";
import { useTranslation } from "react-i18next";

import { useTheme } from "../../../../plugins/styled";
import Button from "../../../common/button";
import Div from "../../../common/div";
import Typography from "../../../common/typography";
import { FundingExplorerListPageLockedWarningContainer } from "./styles";

interface FundingExplorerListLockedWarningProps {
  goToSettings: () => void;
}

const FundingExplorerListLockedWarning = ({
  goToSettings,
}: FundingExplorerListLockedWarningProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Div
      flex="column"
      align="center"
      justify="center"
      style={{ position: "sticky", top: 100, padding: 54 }}
      tablet={{ style: { padding: 0 } }}
    >
      <FundingExplorerListPageLockedWarningContainer>
        <Div flex="column" align="center">
          <Grants size={48} style={{ marginBottom: 16 }} />

          <Typography
            elementTheme="h5"
            color={theme.colors.primary}
            textAlign="center"
            style={{ marginBottom: 8, maxWidth: 400 }}
          >
            {t("grants|fundingExplorerLocked.title")}
          </Typography>
          <Typography
            textAlign="center"
            style={{ maxWidth: 500, width: "100%" }}
          >
            {t("grants|fundingExplorerLocked.subtitle")}
          </Typography>
          <Button
            defaultStyle={theme.colors.primary}
            onClick={goToSettings}
            style={{ margin: "24px 0 32px 0" }}
            textWrap
          >
            {t("grants|fundingExplorerLocked.button")}
          </Button>
          <Typography
            elementTheme="body3"
            textAlign="center"
            style={{ fontStyle: "italic" }}
            color={theme.colors.grey_2}
          >
            {t("grants|fundingExplorerLocked.overline")}
          </Typography>
        </Div>
      </FundingExplorerListPageLockedWarningContainer>
    </Div>
  );
};

export default FundingExplorerListLockedWarning;

