import Search from "@hellodarwin/icons/dist/icons/Search";
import Input from "antd/es/input/Input";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { FundingExplorerFilterProps } from "..";
import FormItem from "../../../../forms/form-layouts/form-item";

const FundingExplorerSearchBar: React.FC<FundingExplorerFilterProps> = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <FormItem name="textQuery" style={{ flex: 4, margin: 0 }}>
      <Input
        placeholder={t(`grants|fundingExplorerFilter.textQueryPlaceholder`)}
        size="large"
        style={{
          fontSize: 18,
          height: 45,
          padding: "0 12px",
        }}
        prefix={
          <Search
            size={20}
            style={{ marginRight: 8 }}
            color={theme.colors.grey_3}
          />
        }
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      />
    </FormItem>
  );
};

export default FundingExplorerSearchBar;

