import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import { PreviewProps } from "@hellodarwin/core/lib/components/common/layout/preview-card";
import StepsManager from "@hellodarwin/core/lib/components/common/steps-manager";
import { StepsManagerColProps } from "@hellodarwin/core/lib/components/common/steps-manager/steps-manager-col";
import Loading from "@hellodarwin/core/lib/components/loading";
import ProgramEmptyState from "@hellodarwin/core/lib/components/programs/single/program-empty-state";
import {
  Program,
  ProgramGrantStatus,
} from "@hellodarwin/core/lib/features/entities/programs-entities";
import { getStringDateFromString } from "@hellodarwin/core/lib/features/helpers/get-formatted-date";
import { getProgramGrantStatusColor } from "@hellodarwin/core/lib/features/helpers/get-status-color";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import {
  GetRoadmapPrograms,
  selectAllRoadmapPrograms,
  selectProgramIsLoading,
} from "../../../features/api/slices/programs-slice";
import { useClientApi } from "../../../features/api/use-client-api";

const ClientOngoingProgramsPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const api = useClientApi();
  const programs: Program[] = useAppSelector(selectAllRoadmapPrograms);
  const isLoading = useAppSelector(selectProgramIsLoading);
  const { selectedLocale } = useLocale();

  useEffect(() => {
    dispatch(GetRoadmapPrograms({ api, locale: selectedLocale }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items: StepsManagerColProps[] = useMemo(() => {
    const createdItems: StepsManagerColProps[] = [];
    Object.values(ProgramGrantStatus).forEach((key, i) => {
      const colItems: PreviewProps[] = [];
      var colTotal = 0;
      for (const program of programs) {
        if (program.program_grant_status === key) {
          colTotal += !!program.program_accepted_amount
            ? program.program_accepted_amount
            : !!program.program_application_amount
              ? program.program_application_amount
              : !!program.program_targeted_amount
                ? program.program_targeted_amount
                : 0;
          colItems.push({
            title: `${program.program_name}`,
            infos: [
              {
                label: t("programs|labels.updatedAt").toLocaleUpperCase(),
                value: !!program.program_updated_at
                  ? getStringDateFromString(
                      program.program_updated_at,
                      selectedLocale
                    )
                  : "",
                Icon: Timeline,
              },
            ],
            tag: {
              text: t(`programs|labels.${program.program_grant_status}`),
              color: getProgramGrantStatusColor(program.program_grant_status),
            },
            ctaText: t("programs|cta.seeStep"),
            parentSlug: "programs",
            id: program.program_id,
          });
        }
      }
      createdItems.push({
        label: t(`programs|labels.${key}`),
        tooltip: t(`programs|tooltips.${key}`),
        assignedKey: `${key}`,
        colTotal: colTotal,
        items: colItems,
      });
    });
    return createdItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programs, selectedLocale]);

  if (isLoading) return <Loading />;
  return (
    <PageLayout
      app="client"
      title={t("navigation.ongoingPrograms")}
      subtitle={t("programs|subTitle")}
      styles={{
        body: {
          overflow: "hidden",
        },
      }}
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.programs"),
          path: "/programs",
        },
        {
          breadcrumbName: t("navigation.ongoingPrograms"),
        },
      ]}
    >
      {programs.length ? (
        <StepsManager
          items={items}
          styles={{
            container: {
              height: `calc(100vh - 40px - 158px - 32px)`,
              width: "100%",
              paddingLeft: "0px",
            },
          }}
        />
      ) : (
        <ProgramEmptyState />
      )}
    </PageLayout>
  );
};

export default ClientOngoingProgramsPage;

