import { ModalProps } from "@hellodarwin/core/lib/components/common/hd-modal";
import ModalLayoutSimple from "@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-simple";
import ModalLayoutWithMeeting from "@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-meeting";
import { AppName } from "@hellodarwin/core/lib/features/entities";
import { getMeetingUrl } from "@hellodarwin/core/lib/features/helpers";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/app-hooks";
import { useClientAnalytics } from "../../../../features/analytics/use-client-analytics";
import { selectProfile } from "../../../../features/api/slices/profile-slice";
import {
  fetchTags,
  selectTags,
} from "../../../../features/api/slices/projects-slice";
import { useClientApi } from "../../../../features/api/use-client-api";
import ProjectSubmitForm from "./project-submit-form";
import SubmitProjectOptions from "./submit-project-options";
import SubmitProjectSuccess from "./submit-project-success";
type ClientSubmitProjectModalsProps = {
  open: boolean;
  handleClose: () => void;
};

export enum SubmitProjectFlowSteps {
  meeting = "Meeting",
  success = "Success",
  form = "Form",
  options = "Options",
}

const ClientSubmitProjectModal = ({
  open,
  handleClose,
}: ClientSubmitProjectModalsProps) => {
  const analytics = useClientAnalytics();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const dispatch = useAppDispatch();
  const api = useClientApi();
  const tags = useAppSelector(selectTags);
  const profile = useAppSelector(selectProfile);
  const [step, setStep] = useState<SubmitProjectFlowSteps>(
    SubmitProjectFlowSteps.options
  );
  const [modalSize, setModalSize] = useState<ModalProps["size"]>("full");

  useEffect(() => {
    if (!open && tags && tags.length > 0) return;
    dispatch(fetchTags({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (step === SubmitProjectFlowSteps.success) setModalSize("small");
    if (step === SubmitProjectFlowSteps.meeting) setModalSize("medium");
    if (step === SubmitProjectFlowSteps.options) setModalSize("large");
    if (step === SubmitProjectFlowSteps.form) setModalSize("medium");
  }, [step]);

  const handleModalClose = () => {
    setStep(SubmitProjectFlowSteps.options);
    handleClose();
    setModalSize("full");
  };

  const openMeeting = () => {
    setStep(SubmitProjectFlowSteps.meeting);
    analytics.meetingClicked();
  };

  const meetingUrl = useMemo(
    () => getMeetingUrl(selectedLocale, "client"),
    [selectedLocale]
  );

  if (step === SubmitProjectFlowSteps.meeting) {
    return (
      <ModalLayoutWithMeeting
        open={open}
        handleCancel={handleModalClose}
        size={modalSize}
        Icon={Timeline}
        iconSize={32}
        styles={{
          body: { padding: 24, paddingTop: 54 },
          content: { padding: 0 },
        }}
        title={t("message.talkToManager")}
        meetingUrl={meetingUrl}
        name={profile.contact_name}
        email={profile.contact_email}
        app={AppName.Client}
        companyName={profile.company_name}
        phone={profile.contact_phone}
      />
    );
  }

  return (
    <ModalLayoutSimple
      open={open}
      handleCancel={handleModalClose}
      size={modalSize}
    >
      {step === SubmitProjectFlowSteps.form ? (
        <ProjectSubmitForm
          closeModal={() => setStep(SubmitProjectFlowSteps.options)}
          openSuccesModal={() => setStep(SubmitProjectFlowSteps.success)}
          tags={tags}
        />
      ) : step === SubmitProjectFlowSteps.success ? (
        <SubmitProjectSuccess handleClose={handleModalClose} />
      ) : (
        step === SubmitProjectFlowSteps.options && (
          <SubmitProjectOptions setStep={setStep} openMeeting={openMeeting} />
        )
      )}
    </ModalLayoutSimple>
  );
};

export default ClientSubmitProjectModal;

