import { useEffect, useState } from "react";

const useDebounce = <T>(value: T, delay: number): T => {
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    if (!isDebouncing) {
      setIsDebouncing(true);
      const handler = setTimeout(() => {
        setDebouncedValue(value);
        setIsDebouncing(false);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;

