import {
  CancelProjectFlowSteps,
  PossibleCancelAnswers,
  Project,
  ProjectUpdateFunctions,
} from "../../../../features/entities";

import CancelConfirmation from "./cancel-confirmation";
import CancelReasonForm from "./cancel-reason-form";

type CancelProjectFlowProps = {
  handleClose: () => void;
  editionFunctions: ProjectUpdateFunctions;
  project: Project;
  flowStep: CancelProjectFlowSteps;
  setFlowStep: (step: CancelProjectFlowSteps) => void;
};

const CancelProjectFlow = ({
  handleClose,
  editionFunctions,
  project,
  flowStep,
  setFlowStep,
}: CancelProjectFlowProps) => {
  const handleCancelProject = (
    selected: PossibleCancelAnswers,
    otherReasonSpecified: string
  ) => {
    if (!!editionFunctions?.cancel) {
      editionFunctions
        ?.cancel(project.rfp_id, selected, otherReasonSpecified)
        .then((i) => {
          setFlowStep(CancelProjectFlowSteps.cancelConfirmation);
        });
    }
  };

  return (
    <>
      {flowStep === CancelProjectFlowSteps.cancelFormStep ? (
        <CancelReasonForm
          handleClose={handleClose}
          handleCancelProject={handleCancelProject}
        />
      ) : (
        flowStep === CancelProjectFlowSteps.cancelConfirmation && (
          <CancelConfirmation handleClose={handleClose} />
        )
      )}
    </>
  );
};

export default CancelProjectFlow;

