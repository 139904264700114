import Typography from "@hellodarwin/core/lib/components/common/typography";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { IconProps } from "@hellodarwin/icons/dist/features/entities/general";
import { ReactNode } from "react";
import { ProjectActionCardContainer } from "./styles";

type ProjectActionCardProps = {
  title: string;
  text: string;
  Icon: (props: IconProps) => ReactNode;
  onClick: () => void;
};

const ProjectActionCard = ({
  title,
  text,
  Icon,
  onClick,
}: ProjectActionCardProps) => {
  const theme = useTheme();
  return (
    <ProjectActionCardContainer className={`fade-in`} onClick={onClick}>
      <Icon size={24} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
        }}
      >
        <Typography.Title
          style={{ margin: 0 }}
          level={2}
          elementTheme="h6"
          noMargin
        >
          {title}
        </Typography.Title>
        <Typography
          textAlign="center"
          elementTheme="body2"
          color={theme.colors.grey_2}
        >
          {text}
        </Typography>
      </div>
    </ProjectActionCardContainer>
  );
};

export default ProjectActionCard;

