import { IconNode } from "@hellodarwin/icons/dist/features/entities/general";
import { PropsWithChildren } from "react";
import { useTheme } from "../../../../../plugins/styled";
import Typography from "../../../../common/typography";
import { SummaryBoxContainer, SummaryBoxContent } from "./styles";

const SummaryBox = ({
  label,
  Icon,
  children,
}: {
  label: string;
  Icon: IconNode;
} & PropsWithChildren) => {
  const theme = useTheme();

  return (
    <SummaryBoxContainer>
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        <Icon size={14} style={{ color: theme.colors.grey_2 }} />
        <Typography color={theme.colors.grey_2} elementTheme="body3">
          {label}
        </Typography>
      </div>
      <SummaryBoxContent>{children}</SummaryBoxContent>
    </SummaryBoxContainer>
  );
};

export default SummaryBox;

