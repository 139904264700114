import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import {
  annualRevenue,
  companySize,
} from "@hellodarwin/core/lib/components/forms/utils/company-infos";
import FundingExplorer from "@hellodarwin/core/lib/components/grants/funding-explorer";
import FilterCompanyCard from "@hellodarwin/core/lib/components/grants/funding-explorer/filter/filter-company-card/index";
import { FundingExplorerFilterValues } from "@hellodarwin/core/lib/components/grants/funding-explorer/filter/types";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { usePagination } from "@hellodarwin/core/lib/features/providers/pagination-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Form from "antd/es/form";
import { useWatch } from "antd/es/form/Form";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import FundingExplorerEditCompanyFieldsModal from "../../components/funding-explorer/funding-explorer-edit-company-fields-modal";
import FundingExplorerSaveCompany from "../../components/funding-explorer/funding-explorer-save-company";
import GrantsDirectoryTotal from "../../components/funding-explorer/grants-directory-total";
import useFundingExplorerLoading from "../../components/funding-explorer/utils/use-funding-explorer-loading";
import { QueryFundingExplorerProps } from "../../features/api/client-api-entities";
import {
  fetchGrantProjects,
  fetchGrantProjectsWithIds,
  selectBestGrantProjects,
  selectFeaturedGrantProjects,
} from "../../features/api/slices/grants-projects-slice";
import {
  fetchFundingExplorerTotalAmount,
  fetchGrantFinancingType,
  fetchGrantServices,
  fetchProvinces,
  queryFundingExplorer,
  selectAllFundingExplorerGrants,
  selectGrantFinancingType,
  selectGrantService,
  selectProvinces,
} from "../../features/api/slices/grants-slice";
import {
  fetchCompany,
  fetchContact,
  selectCompany,
} from "../../features/api/slices/profile-slice";
import {
  fetchIndustries,
  selectIndustriesSectors,
  selectIndustriesSubsectorsForFundingExplorer,
} from "../../features/api/slices/tags-slice";
import { useClientApi } from "../../features/api/use-client-api";

const ClientFundingExplorer: React.FC = () => {
  const dispatch = useAppDispatch();
  const api = useClientApi();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();
  const [filterform] = Form.useForm<FundingExplorerFilterValues>();
  const company = useAppSelector(selectCompany);

  const showCompanyFilterCardState = useState<boolean>(true);
  const [showFilterCompanyCard] = showCompanyFilterCardState;

  const filterValues = useWatch([], filterform);
  const financingType = useAppSelector(selectGrantFinancingType);
  const industries = useAppSelector(selectIndustriesSectors);
  const subindustries = useAppSelector((state) =>
    selectIndustriesSubsectorsForFundingExplorer(
      state,
      filterValues?.industry || []
    )
  );
  const profileSubindustries = useAppSelector((state) =>
    selectIndustriesSubsectorsForFundingExplorer(
      state,
      company.industry_sector || []
    )
  );
  const provinces = useAppSelector(selectProvinces);
  const services = useAppSelector(selectGrantService);

  const grants = useAppSelector(selectAllFundingExplorerGrants);
  const bestProjects = useAppSelector(selectBestGrantProjects);
  const featuredProjects = useAppSelector(selectFeaturedGrantProjects);

  const { activePage, pageSize } = usePagination();

  const [editCompanyModalOpen, setEditCompanyModalOpen] = useState(false);
  const handleEditCompanyModal = () => {
    setEditCompanyModalOpen(!editCompanyModalOpen);
  };

  useEffect(() => {
    if (!!filterValues?.selectedProjects?.length) {
      dispatch(
        fetchGrantProjectsWithIds({
          api,
          locale: selectedLocale,
          projectsIds: filterValues?.selectedProjects ?? [],
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues?.selectedProjects]);

  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  const grantIds = useMemo(
    () => grants?.map((grant) => grant.grant_id),
    [grants]
  );

  useEffect(() => {
    if (grantIds?.length === 0 || !selectedLocale) return;
    dispatch(fetchGrantProjects({ api, locale: selectedLocale, grantIds }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantIds, selectedLocale]);

  const isFundingExplorerLoading = useFundingExplorerLoading();

  const initData = async () => {
    await dispatch(fetchCompany({ api }));
    await dispatch(fetchContact({ api }));
    await dispatch(fetchGrantFinancingType({ api, locale: selectedLocale }));
    await dispatch(fetchIndustries({ api, locale: selectedLocale }));
    await dispatch(fetchProvinces({ api, locale: selectedLocale }));
    await dispatch(fetchGrantServices({ api, locale: selectedLocale }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const fetch = async (values: FundingExplorerFilterValues) => {
    try {
      const queryProps: QueryFundingExplorerProps = {
        locale: selectedLocale,
        page: activePage,
        limit: pageSize,
        companyId: undefined,
        ...values,
      };

      await dispatch(queryFundingExplorer({ api, ...queryProps }));
      await dispatch(fetchFundingExplorerTotalAmount({ api, ...queryProps }));
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <PageLayout
      app="client"
      title={t("navigation.fundingExplorer")}
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.fundingExplorer"),
        },
      ]}
      actions={<GrantsDirectoryTotal />}
      isLoading={!company?.company_id?.length}
      styles={{ container: { overflow: "visible" } }}
    >
      <FundingExplorer
        afterFilters={
          <FilterCompanyCard
            company={company}
            industries={industries}
            subIndustries={profileSubindustries}
            annualRevenue={annualRevenue}
            companySize={companySize}
            provinces={provinces}
            showFilterCompanyCard={showFilterCompanyCard}
          />
        }
        showCompanyFilterCardState={showCompanyFilterCardState}
        filterValues={filterValues}
        filterform={filterform}
        company={company}
        financingType={financingType}
        industries={industries}
        subIndustries={subindustries}
        provinces={provinces}
        services={services}
        companySize={companySize}
        annualRevenue={annualRevenue}
        fetch={fetch}
        bestProjects={bestProjects}
        featuredProjects={featuredProjects}
        grantsProjectsLoading={isFundingExplorerLoading.projects}
        goToSettings={handleEditCompanyModal}
        isLoading={isFundingExplorerLoading.total}
        grants={grants}
      />

      <FundingExplorerEditCompanyFieldsModal
        open={editCompanyModalOpen}
        handleCancel={handleEditCompanyModal}
        company={company}
      />
      <FundingExplorerSaveCompany filterValues={filterValues} />
    </PageLayout>
  );
};

export default ClientFundingExplorer;

