import Budget from "@hellodarwin/icons/dist/icons/Budget";
import Confirm from "@hellodarwin/icons/dist/icons/Confirm";
import Enterprise from "@hellodarwin/icons/dist/icons/Enterprise";
import Industry from "@hellodarwin/icons/dist/icons/Industry";
import Invoices from "@hellodarwin/icons/dist/icons/Invoices";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import React from "react";
import { Grant } from "../../../../../features/entities";
import { dollarFormat, getStringDate } from "../../../../../features/helpers";
import useLocale from "../../../../../features/providers/locale-provider";
import { useTranslations } from "../../../../../features/providers/translations-provider";
import { useTheme } from "../../../../../plugins/styled";
import { CustomUl } from "../../../../../theme/list-style";
import Div from "../../../../common/div";
import HdTag from "../../../../common/hd-tag";
import StatusTags from "./status-tags-list";
import SummaryBox from "./SummaryBox";

const GrantSummary = ({
  application_status,
  grant_providers,
  funding_min_amount,
  funding_max_amount,
  percentage_funding,
  grant_industry_sectors,
  grant_financing_type,
  grant_timeline,
}: Grant) => {
  const { selectedLocale } = useLocale();
  const theme = useTheme();
  const { t } = useTranslations();

  return (
    <>
      <Div
        borderColor={theme.colors.grey_4}
        bordersSide="top"
        flex="row"
        tablet={{ flex: "column" }}
      >
        <SummaryBox label={t("grant_single|eligibleFinancing")} Icon={Budget}>
          <CustomUl>
            {!!funding_min_amount && !!funding_max_amount ? (
              <li>
                {t("grant_single|fromMinToMax", {
                  min: `${dollarFormat(funding_min_amount, selectedLocale)}`,
                  max: `${dollarFormat(funding_max_amount, selectedLocale)}`,
                })}
              </li>
            ) : !!funding_min_amount ? (
              <li>
                {t("grant_single|minCount", {
                  count: `${dollarFormat(funding_min_amount, selectedLocale)}`,
                })}
              </li>
            ) : (
              !!funding_max_amount && (
                <li>
                  {t("grant_single|maxCount", {
                    count: `${dollarFormat(
                      funding_max_amount,
                      selectedLocale
                    )}`,
                  })}
                </li>
              )
            )}
            {!!percentage_funding && (
              <li>
                {t("grant_single|projectCostPercent", {
                  percent: `${percentage_funding}`,
                })}
              </li>
            )}
            {!funding_min_amount &&
              !funding_max_amount &&
              !percentage_funding && <li>{t("grant_single|noCondition")}</li>}
          </CustomUl>
        </SummaryBox>
        <SummaryBox label={t("grant_single|deadlines")} Icon={Timeline}>
          <CustomUl>
            {!!grant_timeline && !!grant_timeline[0]?.opened_at && (
              <li>
                {t("grant_single|openingDate")}
                {getStringDate(
                  new Date(grant_timeline[0].opened_at),
                  selectedLocale
                )}
              </li>
            )}
            {!!grant_timeline && !!grant_timeline[0]?.closed_at && (
              <li>
                {t("grant_single|closingDate")}
                {getStringDate(
                  new Date(grant_timeline[0].closed_at),
                  selectedLocale
                )}
              </li>
            )}
            {(!grant_timeline ||
              (!grant_timeline[0]?.opened_at &&
                !grant_timeline[0]?.closed_at)) && (
              <li>{t("grant_single|timelineUnspecified")}</li>
            )}
          </CustomUl>
        </SummaryBox>
        <SummaryBox label={t("grant_single|financingType")} Icon={Invoices}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 8,
              alignItems: "flex-start",
              height: "fit-content",
            }}
          >
            {React.Children.toArray(
              grant_financing_type?.map((type) => (
                <HdTag color={theme.colors.purple_2} text={type} />
              ))
            )}
          </div>
        </SummaryBox>
      </Div>
      <Div
        borderColor={theme.colors.grey_4}
        bordersSide="top"
        flex="row"
        tablet={{ flex: "column" }}
      >
        <SummaryBox
          label={t("grant_single|eligibleIndustries")}
          Icon={Industry}
        >
          <CustomUl style={{ width: "100%" }}>
            {!!grant_industry_sectors?.length ? (
              React.Children.toArray(
                grant_industry_sectors.map((tag) => <li key={tag}>{tag}</li>)
              )
            ) : (
              <li style={{ width: "100%" }}>
                {t("grant_single|allIndustries")}
              </li>
            )}
          </CustomUl>
        </SummaryBox>
        <SummaryBox label={t("grant_single|grantors")} Icon={Enterprise}>
          <CustomUl style={{ width: "100%" }}>
            {!!grant_providers?.length ? (
              React.Children.toArray(
                grant_providers.map((tag) => <li key={tag}>{tag}</li>)
              )
            ) : (
              <li style={{ width: "100%" }}>{t("grant_single|unspecified")}</li>
            )}
          </CustomUl>
        </SummaryBox>
        <SummaryBox label={t("grant_single|status")} Icon={Confirm}>
          <StatusTags application_status={application_status ?? "closed"} />
        </SummaryBox>
      </Div>
    </>
  );
};

export default GrantSummary;

