import {
  ProjectStatusFlowModal,
  ProjectStatusFlowSteps,
} from "@hellodarwin/core/lib/components/project/status-flow";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/index";
import {
  selectProjectById,
  selectSelectedProjectId,
} from "../../../features/api/slices/projects-slice";

type ProjectStatusModalProps = {
  handleClose: () => void;
  open: boolean;
};

const ProjectStatusModal = ({ open, handleClose }: ProjectStatusModalProps) => {
  const navigate = useNavigate();

  const projectId = useAppSelector(selectSelectedProjectId) || "";
  const project = useAppSelector((state) =>
    selectProjectById(state, projectId)
  );

  if (!project) return <></>;

  const handleStatusClick = (step: ProjectStatusFlowSteps) => {
    switch (step) {
      case ProjectStatusFlowSteps.CancelProjectFlow:
        navigate(`/projects/${project.rfp_id}/status/cancel`);
        break;
      case ProjectStatusFlowSteps.PartnerChooseSelected:
        navigate(`/projects/${project.rfp_id}/status/chosen-sp`);
        break;
      case ProjectStatusFlowSteps.NoPartnerSelectedOptions:
        navigate(`/projects/${project.rfp_id}/status/contact-manager`);
        break;
      default:
        navigate(`/projects/${project.rfp_id}/status`);
        break;
    }
    handleClose();
  };

  return (
    <ProjectStatusFlowModal
      open={open}
      handleClose={handleClose}
      handleClick={handleStatusClick}
    />
  );
};

export default ProjectStatusModal;

