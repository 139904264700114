import Button from "@hellodarwin/core/lib/components/common/button";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";

import { useAppDispatch } from "../../app/index";
import { toggleProjectsModal } from "../../features/api/slices/projects-slice";

const ProjectSubmissionRow = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const handleProjectSubmitModal = () => {
    dispatch(toggleProjectsModal({ isVisible: true, type: "projectSubmit" }));
  };

  return (
    <Button
      defaultStyle={theme.colors.yellow_1}
      size="small"
      onClick={handleProjectSubmitModal}
    >
      {t("project|submit")}
    </Button>
  );
};

export default ProjectSubmissionRow;

