import { SelectProps } from "antd/es/select";
import React from "react";
import { useTranslation } from "../../../../../plugins/i18n";
import Div from "../../../../common/div";
import HdTag from "../../../../common/hd-tag";
import Typography from "../../../../common/typography";
import getFilterTagBarTheme from "../utils/get-filter-tag-bar-theme";
import FilterCompanyInfos from "./filter-company-infos";

interface FilterCompanySectorsProps {
  companyInfo: string[];
  industries: SelectProps["options"];
  theme: any;
  typeSector: string;
}

const FilterCompanySectors: React.FC<FilterCompanySectorsProps> = ({
  companyInfo,
  industries,
  theme,
  typeSector,
}) => {
  const { t } = useTranslation();

  return (
    <FilterCompanyInfos label={`industry${typeSector}`}>
      <Div flex="row" wrap="wrap" gap={8}>
        {companyInfo && companyInfo.length > 0 ? (
          React.Children.toArray(
            companyInfo
              .slice()
              .sort((a, b) => a.localeCompare(b))
              .map((industryId, index) => {
                const industry = industries?.find(
                  (ind) => ind.value === industryId
                );
                return industry ? (
                  <HdTag
                    key={index}
                    text={industry.label}
                    color={getFilterTagBarTheme(
                      "industry" + typeSector,
                      theme,
                      ""
                    )}
                  />
                ) : (
                  <Typography key={index} elementTheme="body2">
                    {t(`grants|grantFilterCard.noIndustry${typeSector}`)}
                  </Typography>
                );
              })
          )
        ) : (
          <Typography elementTheme="body2">
            {t(`grants|grantFilterCard.noIndustry${typeSector}`)}
          </Typography>
        )}
      </Div>
    </FilterCompanyInfos>
  );
};

export default FilterCompanySectors;

