import {
  ApiError,
  ApiErrorInitialState,
  GrantProject,
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/app-store";
import showErrorNotification from "../../utils/show-error-notifications";
import ClientApi from "../client-api";

export const fetchGrantProjects = createAsyncThunk<
  GrantProject[],
  { api: ClientApi; locale: string; grantIds: string[] },
  { rejectValue: ApiError }
>(
  "client/fetchGrantProjects",
  async (
    {
      api,
      locale,
      grantIds,
    }: { api: ClientApi; locale: string; grantIds: string[] },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchBestGrantProjects(locale, grantIds);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);
export const fetchGrantProjectsWithIds = createAsyncThunk<
  GrantProject[],
  { api: ClientApi; locale: string; projectsIds: string[] },
  { rejectValue: ApiError }
>(
  "client/fetchGrantProjectsWithIds",
  async (
    {
      api,
      locale,
      projectsIds,
    }: { api: ClientApi; locale: string; projectsIds: string[] },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrantProjectsWithIds(locale, projectsIds);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const grantProjectsAdapter = createEntityAdapter({
  selectId: (model: GrantProject) => model.grant_project_id,
});

export interface GrantState {
  status: "idle" | "pending";
  error: ApiError;
  grantProjects: EntityState<GrantProject, string>;
  selectedGrantProjects: EntityState<GrantProject, string>;
}

const initialState: GrantState = {
  status: "idle",
  error: ApiErrorInitialState,
  grantProjects: grantProjectsAdapter.getInitialState(),
  selectedGrantProjects: grantProjectsAdapter.getInitialState(),
};

const grantsProjectsSlice = createSlice({
  name: "grants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGrantProjects.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGrantProjects.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.setAll(state.grantProjects, payload ?? []);
      state.status = "idle";
    });
    builder.addCase(fetchGrantProjects.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchGrantProjectsWithIds.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(
      fetchGrantProjectsWithIds.fulfilled,
      (state, { payload }) => {
        grantProjectsAdapter.setAll(state.selectedGrantProjects, payload ?? []);
        state.status = "idle";
      }
    );
    builder.addCase(
      fetchGrantProjectsWithIds.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = "idle";
      }
    );
  },
});

export const { selectAll: selectBestGrantProjects } =
  grantProjectsAdapter.getSelectors(
    (state: RootState) => state.grantsProjects.grantProjects
  );
export const { selectAll: selectFeaturedGrantProjects } =
  grantProjectsAdapter.getSelectors(
    (state: RootState) => state.grantsProjects.selectedGrantProjects
  );

export const selectGrantProjectsLoading = (state: RootState) =>
  state.grantsProjects.status === "pending";

export const grantsProjectsReducer = grantsProjectsSlice.reducer;

