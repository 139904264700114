import { PreviewProps } from "@hellodarwin/core/lib/components/common/layout/preview-card";
import { Project } from "@hellodarwin/core/lib/features/entities/project-entities";
import { getStringDate } from "@hellodarwin/core/lib/features/helpers/get-formatted-date";
import getShortId from "@hellodarwin/core/lib/features/helpers/get-short-id";
import { getProjectStatusColor } from "@hellodarwin/core/lib/features/helpers/get-status-color";
import { TFunction } from "@hellodarwin/core/lib/plugins/i18n/utils";
import { DefaultTheme } from "@hellodarwin/core/lib/plugins/styled";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";

const getProjectPreviewProps = (
  project: Project,
  locale: string,
  t: TFunction<"translation", undefined>,
  theme: DefaultTheme
): PreviewProps => {
  const tagColor: string = getProjectStatusColor(project.status, theme);
  return {
    title:
      project.title && project.title?.length > 0
        ? project.title
        : project.service
          ? project.service
          : `${t("project|project")} ${getShortId(project.rfp_id)}`,
    tag: {
      text: t(`project|statuses.${project.status}`),
      color: tagColor,
    },
    ctaText: t("project|seeProject"),
    percentage: project.completion_rate || 0,
    parentSlug: "projects",
    id: project.rfp_id,
    infos: [
      {
        Icon: Timeline,
        label: t("project|projectTab.projectBrief.projectCreatedAt"),
        value: `${getStringDate(
          new Date(
            `${
              project.activated_at ?? project.created_at ?? project.updated_at
            }`
          ),
          locale
        )}`,
        hide:
          !project.activated_at && !project.created_at && !project.updated_at,
      },
    ],
  };
};

export default getProjectPreviewProps;

