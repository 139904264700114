import { useState } from "react";
import { ProjectUpdateFunctions } from "../../../features/entities/project-entities";
import { ReviewFormSteps } from "../../../features/entities/project-status-flow-entities";
import { Provider } from "../../../features/entities/providers-entities";
import { ReviewRequest } from "../../../features/entities/reviews-entities";
import ModalLayoutSimple from "../../common/hd-modal/layouts/modal-simple";
import ReviewForm from "./review-form";

export interface ReviewFormModalProps {
  provider: Provider;
  rfp_id?: string;
  matchId?: string;
  handleClose: () => void;
  open: boolean;
  editionFunctions: ProjectUpdateFunctions;
  companyTags?: string[];
}

const ReviewFormModal = ({
  provider,
  rfp_id,
  matchId,
  handleClose,
  open,
  editionFunctions,
  companyTags,
}: ReviewFormModalProps) => {
  const handleReviewProvider = async (reviewRequest: ReviewRequest) => {
    if (!!editionFunctions.review) {
      editionFunctions.review(
        rfp_id ?? "",
        provider.provider_id,
        reviewRequest,
        matchId
      );
    }
  };

  const [reviewStep, setReviewStep] = useState<ReviewFormSteps>(
    ReviewFormSteps.Experience
  );

  const handleCancel = () => {
    setReviewStep(ReviewFormSteps.Experience);
    handleClose();
  };

  return (
    <ModalLayoutSimple
      open={open}
      handleCancel={handleCancel}
      size={reviewStep === ReviewFormSteps.Confirm ? "medium" : "large"}
    >
      <ReviewForm
        rfpId={rfp_id}
        providerId={provider.provider_id}
        providerName={provider.name}
        reviewProvider={handleReviewProvider}
        handleClose={handleCancel}
        reviewStep={reviewStep}
        setReviewStep={setReviewStep}
        tags={provider.tags}
        companyTags={companyTags ?? []}
      />
    </ModalLayoutSimple>
  );
};

export default ReviewFormModal;

