import Div from "@hellodarwin/core/lib/components/common/div";
import ProjectStatusFlow, {
  ProjectStatusFlowSteps,
} from "@hellodarwin/core/lib/components/project/status-flow";
import {
  ProjectInitialState,
  ProjectUpdateFunctions,
} from "@hellodarwin/core/lib/features/entities";
import HdLogo from "@hellodarwin/icons/dist/icons/HdLogo";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/index";
import {
  reviewProvider,
  selectAsWinner,
  selectShortlistedMatches,
} from "../../../../features/api/slices/matches-slice";
import {
  cancelProject,
  fetchProject,
  selectProjectById,
  setClientProjectProgression,
} from "../../../../features/api/slices/projects-slice";
import { useClientApi } from "../../../../features/api/use-client-api";

type ClientProjectStatusFlowPageProps = {
  initialStep?: ProjectStatusFlowSteps;
};

const ClientProjectStatusFlowPage = ({
  initialStep,
}: ClientProjectStatusFlowPageProps) => {
  const dispatch = useAppDispatch();
  const api = useClientApi();
  const navigate = useNavigate();
  const { projectId } = useParams<{
    projectId: string;
  }>();

  const activeProject = useAppSelector((state) =>
    selectProjectById(state, projectId || "")
  );

  const shortlistMatches = useAppSelector(selectShortlistedMatches);

  const editionFunctions: ProjectUpdateFunctions = {
    cancel: async (rfp_id, canceled_reason, canceled_reason_specified) => {
      await dispatch(
        cancelProject({
          api,
          rfp_id,
          canceled_reason,
          canceled_reason_specified,
        })
      );
    },
    review: async (projectId, providerId, reviewRequest, matchId) => {
      if (matchId === undefined) {
        matchId = "";
      }
      await dispatch(
        reviewProvider({ api, reviewRequest, providerId, projectId, matchId })
      );
    },

    selectAsWinner: async (matchId) => {
      await dispatch(selectAsWinner({ api, matchId }));
    },
    selectProgression: async (projectId, clientProgression) => {
      await dispatch(
        setClientProjectProgression({ api, projectId, clientProgression })
      );
    },
  };

  const handleClose = () => {
    if (activeProject?.rfp_id) {
      navigate(`/projects/${activeProject?.rfp_id}`);
    } else {
      navigate(`/projects`);
    }
  };

  const handleChangeFlow = (step: ProjectStatusFlowSteps) => {
    switch (step) {
      case ProjectStatusFlowSteps.CancelProjectFlow:
        navigate(`/projects/${projectId}/status/cancel`);
        break;
      case ProjectStatusFlowSteps.PartnerChooseSelected:
        navigate(`/projects/${projectId}/status/chosen-sp`);
        break;
      case ProjectStatusFlowSteps.NoPartnerSelectedOptions:
        navigate(`/projects/${projectId}/status/contact-manager`);
        break;
      default:
        navigate(`/projects/${projectId}/status`);
        break;
    }
  };

  useEffect(() => {
    if (projectId && activeProject?.rfp_id !== projectId) {
      dispatch(fetchProject({ api, projectId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Div flex="column" align="center" gap={32}>
      <HdLogo
        onClick={() => navigate("/")}
        width={162}
        type="primary"
        backgroundType="light"
        color="b/w"
      />
      <ProjectStatusFlow
        project={activeProject || ProjectInitialState}
        shortlistMatches={shortlistMatches}
        editionFunctions={editionFunctions}
        initialStep={initialStep}
        handleClose={handleClose}
        handleChangeFlow={handleChangeFlow}
      />
    </Div>
  );
};

export default ClientProjectStatusFlowPage;

