import { Project } from "../../../../features/entities/project-entities";
import { useTranslation } from "../../../../plugins/i18n";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../../common/button";
import Div from "../../../common/div";
import Typography from "../../../common/typography";
import ProjectSingleTile from "./project-single-tile";

type ProjectSideBarCtaProps = {
  project: Project;
  navigate: (path: string) => void;
};

const ProjectSideBarCta = ({ project, navigate }: ProjectSideBarCtaProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const handleClick = () => {
    navigate(`/projects/${project.rfp_id}/status/contact-manager`);
  };

  return (
    <ProjectSingleTile>
      <Div gap={18} flex="column">
        <Typography medium elementTheme="body3" color={theme.colors.grey_2}>
          {t("project|projectPage.questions")}
        </Typography>
        <Button
          onClick={handleClick}
          size="extra-small"
          defaultStyle={theme.colors.purple_3}
          fullWidth
        >
          {t("project|projectPage.bookConsultation")}
        </Button>
      </Div>
    </ProjectSingleTile>
  );
};

export default ProjectSideBarCta;

