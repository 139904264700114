import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import {
  ApiError,
  ApiErrorInitialState,
  ClientMappedProject,
  ClientProjectRequest,
  Company,
  Contact,
  Match,
  ParsedClientTag,
  Project,
  ProjectDescription,
  ShortlistParams,
} from "@hellodarwin/core/lib/features/entities";
import { RootState } from "../../../app/app-store";
import { StatusFlowModalState } from "../../../components/project/project-action-modal/project-status-flow-modal";
import showErrorNotification from "../../utils/show-error-notifications";
import { createProjectAdapter } from "../adapters/project-adapter";
import ClientApi from "../client-api";

const projectAdapter = createProjectAdapter();

export interface ProjectModalState {
  isVisible: boolean;
  type: string;
  props?: StatusFlowModalState;
}
export interface ProjectsState {
  status: "idle" | "pending";
  error: ApiError;
  projects: { [key: string]: ClientMappedProject };
  selectedProject: {
    projectId: string;
  };
  tags: { [key: string]: ParsedClientTag };
  modal: ProjectModalState;
}

const initialState: ProjectsState = {
  status: "idle",
  error: ApiErrorInitialState,
  projects: projectAdapter.getInitialState(),
  selectedProject: { projectId: "" },
  tags: {} as { [key: string]: ParsedClientTag },
  modal: {
    isVisible: false,
    type: "",
  },
};

export const fetchProject = createAsyncThunk<
  Project,
  { api: ClientApi; projectId: string },
  { rejectValue: ApiError }
>(
  "client/fetchProject",
  async (
    { api, projectId }: { api: ClientApi; projectId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchProject(projectId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchProjects = createAsyncThunk<
  Project[],
  { api: ClientApi; limit: number },
  { rejectValue: ApiError }
>(
  "client/fetchProjects",
  async (
    { api, limit }: { api: ClientApi; limit: number },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchProjects(limit);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export const createProject = createAsyncThunk<
  Project,
  { api: ClientApi; project: ClientProjectRequest },
  { rejectValue: ApiError; state: RootState }
>(
  "client/createProjects",
  async (
    { api, project }: { api: ClientApi; project: ClientProjectRequest },
    { rejectWithValue }
  ) => {
    try {
      return await api.createProject(project);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { projects } = getState();
      if (projects.status === "pending") return false;
    },
  }
);

export const askMoreProviderProject = createAsyncThunk<
  Project,
  { api: ClientApi; projectId: string; askMoreProviderMessage: string },
  { rejectValue: ApiError; state: RootState }
>(
  "client/askMoreProviders",
  async (
    {
      api,
      projectId,
      askMoreProviderMessage,
    }: { api: ClientApi; projectId: string; askMoreProviderMessage: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.askMoreProvider(projectId, askMoreProviderMessage);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { projects } = getState();
      if (projects.status === "pending") return false;
    },
  }
);

export const sendShortlistMessage = createAsyncThunk<
  Match,
  { api: ClientApi; matchId: string; shortlistMessage: string },
  { rejectValue: ApiError; state: RootState }
>(
  "client/sendShortlistMessage",
  async (
    {
      api,
      matchId,
      shortlistMessage,
    }: { api: ClientApi; matchId: string; shortlistMessage: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.shortlistMessage({
        matchId: matchId,
        shortlist_message: shortlistMessage,
      } as ShortlistParams);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { projects } = getState();
      if (projects.status === "pending") return false;
    },
  }
);

export const fetchProjectDescription = createAsyncThunk<
  ProjectDescription[],
  { api: ClientApi; projectId: string },
  { rejectValue: ApiError }
>(
  "client/fetchProjectDescription",
  async (
    { api, projectId }: { api: ClientApi; projectId: string },
    { rejectWithValue }
  ) => {
    try {
      const projectDescription = api.fetchProjectDescription(projectId);
      return projectDescription;
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export const createCompanyIfNotExist = createAsyncThunk<
  Company,
  {
    api: ClientApi;
    company: Company;
  },
  { rejectValue: ApiError }
>(
  "client/createContactifNotExist",
  async (
    {
      api,
      company,
    }: {
      api: ClientApi;
      company: Company;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.createCompanyIfNotExist(company);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export const createContactIfNotExist = createAsyncThunk<
  Contact,
  {
    api: ClientApi;
    contact: Contact;
  },
  { rejectValue: ApiError }
>(
  "client/createContactifNotExist",
  async (
    {
      api,
      contact,
    }: {
      api: ClientApi;
      contact: Contact;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.createContactIfNotExist(contact);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchTags = createAsyncThunk<
  { [key: string]: ParsedClientTag },
  { api: ClientApi },
  { rejectValue: ApiError }
>(
  "client/fetchTags",
  async ({ api }: { api: ClientApi }, { rejectWithValue }) => {
    try {
      return await api.fetchTags();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export const cancelProject = createAsyncThunk<
  string,
  {
    api: ClientApi;
    rfp_id: string;
    canceled_reason: string;
    canceled_reason_specified: string;
  },
  { rejectValue: ApiError; state: RootState }
>(
  "client/cancelProject",
  async (
    {
      api,
      rfp_id,
      canceled_reason,
      canceled_reason_specified,
    }: {
      api: ClientApi;
      rfp_id: string;
      canceled_reason: string;
      canceled_reason_specified: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.cancelProject(
        rfp_id,
        canceled_reason,
        canceled_reason_specified
      );
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { projects } = getState();
      if (projects.status === "pending") return false;
    },
  }
);

export const setClientProjectProgression = createAsyncThunk<
  string,
  {
    api: ClientApi;
    projectId: string;
    clientProgression: number;
  },
  { rejectValue: ApiError; state: RootState }
>(
  "client/setClientProjectProgression",
  async (
    {
      api,
      projectId,
      clientProgression,
    }: {
      api: ClientApi;
      projectId: string;
      clientProgression: number;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.setClientProjectProgression(
        projectId,
        clientProgression
      );
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { projects } = getState();
      if (projects.status === "pending") return false;
    },
  }
);

export const toggleProjectsModal = createAction<ProjectModalState>(
  "client/toggleProjectsModal"
);

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProject.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchProject.fulfilled, (state, { payload }) => {
      state.projects = projectAdapter.addOne(state.projects, payload);
      state.status = "idle";
    });
    builder.addCase(fetchProject.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });

    builder.addCase(fetchProjects.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchProjects.fulfilled, (state, { payload }) => {
      if (payload !== null) {
        state.projects = projectAdapter.setAll(state.projects, payload);
      }
      state.status = "idle";
    });
    builder.addCase(fetchProjects.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(createProject.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(createProject.fulfilled, (state, { payload }) => {
      projectAdapter.addOne(state.projects, payload);
      state.status = "idle";
    });
    builder.addCase(createProject.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(setSelectedProjectId, (state, { payload }) => {
      state.selectedProject.projectId = payload;
    });
    builder.addCase(fetchTags.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchTags.fulfilled, (state, { payload }) => {
      state.tags = payload;
      state.status = "idle";
    });
    builder.addCase(fetchTags.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(cancelProject.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(cancelProject.fulfilled, (state, { payload }) => {
      state.projects = projectAdapter.removeOne(state.projects, payload);
      state.status = "idle";
    });
    builder.addCase(cancelProject.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(toggleProjectsModal, (state, { payload }) => {
      state.modal = payload;
    });
  },
});

export const selectProject = createSelector(
  [
    (state: RootState) => state.projects.projects,
    (state: RootState) => state.projects.selectedProject.projectId,
  ],
  (projects, projectId) => {
    return projects[projectId];
  }
);

export const selectSelectedProjectId = (state: RootState) =>
  state.projects.selectedProject.projectId;

export const setSelectedProjectId = createAction<string>(
  "client/setSelectedProjectId"
);
export const selectProjects = createSelector(
  (state: RootState) => state.projects.projects,
  (projects) => projectAdapter.selectAll(projects)
);
export const selectProjectsLoading = (state: RootState) =>
  state.projects.status === "pending";
export const selectProjectById = createSelector(
  [
    (state: RootState, _) => state.projects.projects,
    (_, projectId: string) => projectId,
  ],
  (projects, projectId) => projectAdapter.selectById(projects, projectId)
);

export const selectTags = createSelector(
  (state: RootState) => state.projects.tags,
  (tags) => Object.values(tags)
);

export const selectProjectsState = (state: RootState) => state.projects;

export const selectProjectsModal = (state: RootState) => state.projects.modal;
export const projectsReducer = projectsSlice.reducer;

