import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { MilestoneAttachment } from "@hellodarwin/core/lib/features/entities";
import { getStringDate } from "@hellodarwin/core/lib/features/helpers";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import theme from "@hellodarwin/core/lib/theme";
import Doc from "@hellodarwin/icons/dist/icons/Doc";
import Download from "@hellodarwin/icons/dist/icons/Download";
import Image from "@hellodarwin/icons/dist/icons/Image";
import PDF from "@hellodarwin/icons/dist/icons/PDF";
import Avatar from "antd/es/avatar/avatar";
import List from "antd/es/list";
import { ReactNode } from "react";

type MilestoneAttachmentsListProps = {
  attachments: MilestoneAttachment[];
};
const MilestoneAttachmentsList = ({
  attachments,
}: MilestoneAttachmentsListProps) => {
  const { selectedLocale } = useLocale();

  const getFileIcon = (extension?: string): ReactNode => {
    let element = <></>;
    switch (extension) {
      case ".pdf":
        element = element = <PDF size={16} />;
        break;
      case ".jpeg":
      case ".jpg":
      case ".png":
        element = <Image size={16} />;
        break;
      case ".doc":
      case ".xls":
        element = <Doc size={16} />;
        break;
    }
    return element;
  };
  return (
    <List
      itemLayout="horizontal"
      dataSource={attachments}
      renderItem={(item, index) => (
        <List.Item key={item.milestone_attachment_display_name}>
          <Div
            flex="row"
            justify="space-between"
            align="center"
            tablet={{ flex: "column", align: "flex-start", gap: 16 }}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  size={77}
                  style={{
                    backgroundColor: "#FCF4FF",
                    color: theme.colors.purple_3,
                  }}
                >
                  {getFileIcon(item.milestone_attachment_extension)}
                </Avatar>
              }
              title={
                <Typography elementTheme="body2" bold>
                  {item.milestone_attachment_filename}
                </Typography>
              }
              description={
                <Typography elementTheme="caption">
                  {getStringDate(
                    new Date(item.milestone_attachment_updated_at || 0),
                    selectedLocale
                  )}
                </Typography>
              }
            />
            <Div
              fitContent
              tablet={{
                flex: "column",
                align: "flex-end",
                style: { width: "100%" },
              }}
            >
              <a
                href={item.milestone_attachment_url}
                rel="noopener noreferrer"
                download
              >
                <Download size={18} />
              </a>
            </Div>
          </Div>
        </List.Item>
      )}
    />
  );
};

export default MilestoneAttachmentsList;

