import Button from "@hellodarwin/core/lib/components/common/button";
import ModalLayoutWithIcon from "@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-icon";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import Confirm from "@hellodarwin/icons/dist/icons/Confirm";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import {
  closeMatchModal,
  openMatchModal,
} from "../../../features/api/slices/global-slice";
import {
  selectAsWinner,
  selectMatchesLoading,
  selectProjectMatchById,
  shortlist,
} from "../../../features/api/slices/matches-slice";
import {
  selectProjectById,
  selectProjectsLoading,
  selectSelectedProjectId,
} from "../../../features/api/slices/projects-slice";
import { useClientApi } from "../../../features/api/use-client-api";
import ErrorModal from "./error-modal";

interface ProviderConfirmModalProps {
  open: boolean;
  action: string | undefined;
  matchId: string;
  source?: string;
}

const ProviderConfirmModal = ({
  open,
  action,
  matchId,
  source,
}: ProviderConfirmModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const api = useClientApi();
  const dispatch = useAppDispatch();

  const matchloading = useAppSelector(selectMatchesLoading);
  const projectloading = useAppSelector(selectProjectsLoading);

  const selectedMatch = useAppSelector((state) =>
    selectProjectMatchById(state, matchId)
  );

  const projectId = useAppSelector(selectSelectedProjectId) || "";
  const project = useAppSelector((state) =>
    selectProjectById(state, projectId)
  );
  const [error, setError] = useState<number>();

  const closeErrorModal = () => {
    setError(undefined);
  };

  const handleConfirm = () => {
    const matchId = selectedMatch?.match_id || "";
    (async () => {
      switch (action) {
        case "shortlist":
          await dispatch(
            shortlist({ api, matchId, source: source ?? "Client" })
          )
            .then(unwrapResult)
            .catch((err: any) => {
              handleError(err.response.data.error_code);
            });

          dispatch(
            openMatchModal({
              isVisible: true,
              type: "shortlist-message",
              matchId,
              source: "Client",
            })
          );

          break;
        case "winner":
          await dispatch(selectAsWinner({ api, matchId }));

          dispatch(closeMatchModal());
          break;
      }
    })();
  };

  const title = t(`confirm-modal|${action}.title`, {
    provider: selectedMatch?.provider.name,
  });
  const body = t(`confirm-modal|${action}.body`, {
    provider: selectedMatch?.provider.name,
    maxQuotes: project?.provider_max,
  });
  const buttonText = t(`confirm-modal|${action}.button`);

  const handleError = (errorCode: number) => {
    setError(errorCode);
  };

  const handleClose = () => {
    dispatch(closeMatchModal());
  };

  if (error !== undefined) {
    return (
      <ErrorModal isVisible={true} error={error} onCancel={closeErrorModal} />
    );
  }

  return (
    <ModalLayoutWithIcon
      open={open}
      handleCancel={handleClose}
      size="medium"
      title={title}
      description={body}
      Icon={Confirm}
      iconColor={theme.colors.green_1}
      iconSize={64}
      Actions={
        <>
          <Button
            onClick={handleClose}
            defaultStyle={theme.colors.white_1}
            disabled={matchloading || projectloading}
            size="small"
            style={{ flex: 1 }}
          >
            {t("common|button.cancel")}
          </Button>
          <Button
            defaultStyle={theme.colors.purple_1}
            onClick={handleConfirm}
            loading={matchloading || projectloading}
            size="small"
            style={{ flex: 1 }}
          >
            {buttonText}
          </Button>
        </>
      }
    />
  );
};

export default ProviderConfirmModal;

