import Div from "@hellodarwin/core/lib/components/common/div";
import Loading from "@hellodarwin/core/lib/components/loading";
import {
  GrantTotalAmounts,
  Program,
  ProgramGrantStatus,
} from "@hellodarwin/core/lib/features/entities";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useEffect, useState } from "react";
import RoadmapDashboardSectionFooter from "./roadmap-dashboard-section-footer";
import RoadmapDashboardSectionHeader from "./roadmap-dashboard-section-header";
import RoadmapDashboardSectionTotalsList from "./roadmap-dashboard-section-totals-list";

interface RoadmapProgramsSiderProps {
  programs: Program[];
  isLoading: boolean;
}

const ProgramSummarySider = ({
  programs,
  isLoading,
}: RoadmapProgramsSiderProps) => {
  const { t } = useTranslation();
  const { t: hdT } = useTranslations();

  const initialStatusTotals = [
    { financing_type: "Total", total_funding: 0, label: "Total" },
    {
      financing_type: "opportunities",
      total_funding: 0,
      label: hdT(`programs_labels|identified_opportunitites`),
    },
    {
      financing_type: "ongoing",
      total_funding: 0,
      label: hdT(`programs_labels|ongoing_work_hellodarwin`),
    },
    {
      financing_type: "accepted",
      total_funding: 0,
      label: hdT(`programs_labels|accepted`),
    },
  ];

  const [grantsTotals, setGrantsTotals] =
    useState<GrantTotalAmounts[]>(initialStatusTotals);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setStatusTotalValue = (key: string, value: number) => {
    setGrantsTotals((prevStatusTotals) => {
      const index = prevStatusTotals?.findIndex(
        (itm) => itm.financing_type === key
      );

      if (index !== null) {
        prevStatusTotals[index] = {
          ...prevStatusTotals[index],
          total_funding: value,
        };
      } else {
        prevStatusTotals.push({
          financing_type: key,
          total_funding: value,
        });
      }

      return prevStatusTotals;
    });
  };

  useEffect(() => {
    var totals = [0, 0, 0];
    for (const program of programs) {
      switch (program.program_grant_status) {
        case ProgramGrantStatus.IdentifiedOpportunities:
        case ProgramGrantStatus.ConfirmedOpportunities:
          totals[0] += program.program_targeted_amount || 0;
          break;
        case ProgramGrantStatus.Accepted:
          totals[2] += program.program_accepted_amount || 0;
          break;
        case ProgramGrantStatus.Refused:
        case ProgramGrantStatus.NotSubmited:
          break;
        default:
          totals[1] += program.program_application_amount || 0;
      }

      setStatusTotalValue("opportunities", totals[0]);
      setStatusTotalValue("ongoing", totals[1]);
      setStatusTotalValue("accepted", totals[2]);

      setStatusTotalValue("Total", totals[0] + totals[1] + totals[2]);
    }
  }, [programs]);

  if (isLoading) return <></>;

  return (
    <Div flex="column" gap={24}>
      <RoadmapDashboardSectionHeader
        title={t(`programs|totals.programsTitle`)}
        grantsTotals={grantsTotals}
        isLoading={isLoading}
        inversed
      />
      {!isLoading ? (
        <RoadmapDashboardSectionTotalsList
          grantsTotals={grantsTotals}
          inversed
        />
      ) : (
        <Loading />
      )}
      <RoadmapDashboardSectionFooter
        buttonText={t(`programs|totals.programsButton`)}
        buttonLink={`/programs/roadmap`}
      />
    </Div>
  );
};

export default ProgramSummarySider;

