import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Project } from "@hellodarwin/core/lib/features/entities";
import ProjectType from "@hellodarwin/core/lib/features/enums/project-type";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Help from "@hellodarwin/icons/dist/icons/Help";
import MoreCandidates from "@hellodarwin/icons/dist/icons/MoreCandidates";
import { MenuProps } from "antd/es/menu";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import {
  selectCandidateMatches,
  selectClassicMatches,
  selectHasWinner,
  selectRejectedMatches,
  selectShortlistedMatches,
} from "../../../features/api/slices/matches-slice";
import {
  setSelectedProjectId,
  toggleProjectsModal,
} from "../../../features/api/slices/projects-slice";
import ProjectMatchesList from "./matches-list";

type ProjectMatchesProps = {
  project: Project;
};
const ProjectMatches = ({ project }: ProjectMatchesProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const proposals = useAppSelector(selectShortlistedMatches);
  const candidates = useAppSelector(selectCandidateMatches);
  const matches = useAppSelector(selectClassicMatches);
  const rejected = useAppSelector(selectRejectedMatches);
  const hasWinner = useAppSelector(selectHasWinner);

  const handleHelpClick = () => {
    if (!!project) {
      navigate(`/projects/${project.rfp_id}/status/contact-manager`);
    }
  };

  const handleMoreCandidates = () => {
    if (!!project) {
      dispatch(setSelectedProjectId(project.rfp_id));
      dispatch(
        toggleProjectsModal({ isVisible: true, type: "moreCandidates" })
      );
    }
  };

  const handleAdditionalShortlist = () => {
    if (!!project) {
      dispatch(setSelectedProjectId(project.rfp_id));
      dispatch(
        toggleProjectsModal({ isVisible: true, type: "additionalShortlisting" })
      );
    }
  };

  const matchesActions: MenuProps["items"] = [
    {
      label: (
        <Div flex="row" align="center" gap={10}>
          <Typography
            color={theme.colors.grey_2}
            elementTheme="caption"
            bold
            fitContent
          >
            <MoreCandidates size={16} />
          </Typography>
          <Typography color={theme.colors.grey_2} elementTheme="caption" bold>
            {t("project|projectTab.cta.moreCandidates")}
          </Typography>
        </Div>
      ),
      key: 1,
      onClick: handleMoreCandidates,
    },
  ];

  const proposalsActions: MenuProps["items"] = [
    {
      label: (
        <Div flex="row" align="center" gap={10}>
          <Typography
            color={theme.colors.grey_2}
            elementTheme="caption"
            bold
            fitContent
          >
            <Help size={16} />
          </Typography>
          <Typography color={theme.colors.grey_2} elementTheme="caption" bold>
            {t("project|projectTab.cta.getHelpChoosing")}
          </Typography>
        </Div>
      ),
      key: 1,
      onClick: handleHelpClick,
    },
    {
      label: (
        <Div flex="row" align="center" gap={10}>
          <Typography
            color={theme.colors.grey_2}
            elementTheme="caption"
            bold
            fitContent
          >
            <MoreCandidates size={16} />
          </Typography>
          <Typography color={theme.colors.grey_2} elementTheme="caption" bold>
            {t("project|projectTab.cta.additionalShortlist")}
          </Typography>
        </Div>
      ),
      key: 2,
      onClick: handleAdditionalShortlist,
    },
  ];
  return (
    <Div gap={20} flex="column">
      {project.type === ProjectType.RaiseHand ? (
        <>
          <ProjectMatchesList
            matches={candidates}
            matchesType={"candidates"}
            project={project}
            hasWinner={hasWinner}
            actionMenuItems={matchesActions}
            handleMore={handleMoreCandidates}
            matchesMax={project.raise_hand_max}
          />
          <ProjectMatchesList
            matches={proposals}
            matchesType={"shortlist"}
            project={project}
            hasWinner={hasWinner}
            actionMenuItems={proposalsActions}
            handleMore={handleAdditionalShortlist}
            matchesMax={project.provider_max}
          />
        </>
      ) : (
        <>
          <ProjectMatchesList
            matches={matches}
            matchesType={"classic"}
            project={project}
            hasWinner={hasWinner}
            actionMenuItems={matchesActions}
            handleMore={handleMoreCandidates}
            matchesMax={project.provider_max}
            isClassic
          />
        </>
      )}
      <ProjectMatchesList
        matches={rejected}
        matchesType={"rejected"}
        project={project}
        hasWinner={hasWinner}
        handleMore={handleAdditionalShortlist}
        matchesMax={project.provider_max}
      />
    </Div>
  );
};

export default ProjectMatches;

