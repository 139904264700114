import ModalLayoutWithMeeting, {
  ModalLayoutWithMeetingProps,
} from "@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-meeting";
import { AppName } from "@hellodarwin/core/lib/features/entities/core-entities";
import { getMeetingUrl } from "@hellodarwin/core/lib/features/helpers";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import MoreCandidates from "@hellodarwin/icons/dist/icons/MoreCandidates";
import { useState } from "react";
import { useAppSelector } from "../../../app/app-hooks";
import { selectProfile } from "../../../features/api/slices/profile-slice";
import {
  selectProjectById,
  selectSelectedProjectId,
} from "../../../features/api/slices/projects-slice";
import ErrorModal from "../../provider/provider-action-modal/error-modal";
interface ProjectAdditionalShortlistModalProps
  extends Omit<
    ModalLayoutWithMeetingProps,
    "title" | "Icon" | "meetingUrl" | "app"
  > {}

const ProjectAdditionalShortlistModal = ({
  ...modalProps
}: ProjectAdditionalShortlistModalProps) => {
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const projectId = useAppSelector(selectSelectedProjectId) || "";
  const project = useAppSelector((state) =>
    selectProjectById(state, projectId)
  );

  const [error, setError] = useState<number>();
  const profile = useAppSelector(selectProfile);

  const meetingUrl = getMeetingUrl(selectedLocale, "client");

  const closeError = () => {
    setError(undefined);
  };

  if (!project) return <></>;

  if (!!error)
    return (
      <ErrorModal error={error} isVisible={!!error} onCancel={closeError} />
    );

  return (
    <ModalLayoutWithMeeting
      {...modalProps}
      size="medium"
      Icon={MoreCandidates}
      iconSize={64}
      title={t("project|modal.additionalShortlistings.title")}
      description={t("project|modal.additionalShortlistings.text", {
        raiseHandMax: project.raise_hand_max,
      })}
      meetingUrl={meetingUrl}
      name={profile.contact_name}
      email={profile.contact_email}
      app={AppName.Client}
      companyName={profile.company_name}
      phone={profile.contact_phone}
      additionalParams={{
        type: t("project|modal.additionalShortlistings.message"),
      }}
    />
  );
};

export default ProjectAdditionalShortlistModal;

