import React, { PropsWithChildren, useMemo } from "react";
import { ScrollingContainer, ScrollingContent } from "./styles";

export interface DynamicScrollerProps extends PropsWithChildren {
  speed?: number;
  inversed?: boolean;
}

const DynamicScroller: React.FC<DynamicScrollerProps> = ({
  children,
  speed = 20,
  inversed = false,
}) => {
  const items = useMemo(() => React.Children.toArray(children), [children]);

  if (!items.length) return <></>;
  return (
    <ScrollingContainer>
      <ScrollingContent $speed={speed} $inversed={inversed}>
        {React.Children.toArray([...items, ...items].map((itm) => <>{itm}</>))}
      </ScrollingContent>
    </ScrollingContainer>
  );
};
export default DynamicScroller;

