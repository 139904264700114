import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Projects from "@hellodarwin/icons/dist/icons/Projects";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import { Dispatch, SetStateAction } from "react";
import { SubmitProjectFlowSteps } from ".";
import ProjectActionCard from "./project-action-card";

interface SubmitProjectOptionsProps {
  openMeeting: () => void;
  setStep: Dispatch<SetStateAction<SubmitProjectFlowSteps>>;
}

const SubmitProjectOptions = ({
  setStep,
  openMeeting,
}: SubmitProjectOptionsProps) => {
  const { t } = useTranslation();
  return (
    <Div flex="column" justify={"center"} align={"center"} gap={32}>
      <Typography.Title level={4}>
        {t("project|projectHeader.headerTitle")}
      </Typography.Title>
      <Div
        flex={"row"}
        tablet={{ flex: "column", align: "center" }}
        gap={24}
        justify={"center"}
        align={"stretch"}
      >
        <ProjectActionCard
          onClick={openMeeting}
          title={t("project|projectActionCard.bookAMeeting.title")}
          text={t("project|projectActionCard.bookAMeeting.text")}
          Icon={Timeline}
        />
        <ProjectActionCard
          onClick={() => setStep(SubmitProjectFlowSteps.form)}
          title={t("project|projectActionCard.submitAProject.title")}
          text={t("project|projectActionCard.submitAProject.text")}
          Icon={Projects}
        />
      </Div>
    </Div>
  );
};

export default SubmitProjectOptions;

