import AdminCard from "@hellodarwin/core/lib/components/admin/admin-card";
import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { getMilestoneStatusColor } from "@hellodarwin/core/lib/features/helpers";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import parse, {
  Element,
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from "html-react-parser";
import { ReactNode, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/index";
import MilestoneAttachmentsList from "../../../components/program/milestones/attachments-list";
import MilestoneHeaderInfomrations from "../../../components/program/milestones/milestone-header-informations";
import {
  fetchAdmin,
  selectAdminByAdminId,
} from "../../../features/api/slices/global-slice";
import { selectProfile } from "../../../features/api/slices/profile-slice";
import {
  GetProgram,
  fetchMilestone,
  fetchMilestoneAttachments,
  selectAllMilestoneAttachments,
  selectMilestoneById,
  selectProgramById,
} from "../../../features/api/slices/programs-slice";
import { useClientApi } from "../../../features/api/use-client-api";

const ClientSingleMilestonePage = () => {
  const { t } = useTranslation();

  const api = useClientApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { milestoneId } = useParams<{
    milestoneId: string;
  }>();

  const milestone = useAppSelector((state) =>
    selectMilestoneById(state, milestoneId || "")
  );
  const attachments = useAppSelector(selectAllMilestoneAttachments);
  const profile = useAppSelector(selectProfile);
  const program = useAppSelector((state) =>
    selectProgramById(state, milestone?.milestone_program_id || "")
  );

  const { selectedLocale } = useLocale();

  useEffect(() => {
    if (milestoneId) {
      dispatch(fetchMilestone({ api, milestoneId }));
      dispatch(fetchMilestoneAttachments({ api, milestoneId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestoneId]);

  useEffect(() => {
    if (profile.company_id) {
      dispatch(
        GetProgram({
          api,
          programId: milestone?.milestone_program_id || "",
          locale: selectedLocale,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const adminId = useMemo(() => milestone?.milestone_caller || "", [milestone]);
  const admin = useAppSelector((state) => selectAdminByAdminId(state, adminId));
  useEffect(() => {
    dispatch(fetchAdmin({ api, adminId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminId]);

  if (!milestone?.milestone_id) return <></>;
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.attribs) {
        const props = attributesToProps(domNode.attribs);
        if (domNode.name === "ul") {
          return (
            <Typography elementTheme="body2">
              <ul {...props}>{domToReact(domNode.children)}</ul>
            </Typography>
          );
        }
        if (domNode.name === "p") {
          return (
            <Typography elementTheme="body2">
              {domToReact(domNode.children)}
            </Typography>
          );
        }
      }
    },
  };

  const Section = ({
    title,
    children,
  }: {
    title: string;
    children: ReactNode;
  }) => {
    return (
      <Div flex="column" gap={16}>
        <Typography elementTheme="subtitle2">{title}</Typography>

        <Div>{children}</Div>
      </Div>
    );
  };

  const handleBack = () => {
    navigate("/programs/digital-transformations");
  };

  return (
    <PageLayout
      title={milestone?.milestone_title}
      status={
        milestone.milestone_status && {
          text: t(`propulsion|milestoneStatus.${milestone.milestone_status}`),
          color: getMilestoneStatusColor(milestone.milestone_status),
        }
      }
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.programs"),
          path: "/programs",
        },
        {
          breadcrumbName: program?.program_name,
          path: "/programs/digital-transformations",
        },
        {
          breadcrumbName: milestone?.milestone_title,
        },
      ]}
      beforeHeader={
        <Button
          defaultStyle={theme.colors.transparent}
          withArrowLeft
          onClick={handleBack}
          size="small"
          style={{ marginBottom: 32 }}
        >
          {t("button.back")}
        </Button>
      }
      subtitle={<MilestoneHeaderInfomrations milestone={milestone} />}
      app="client"
    >
      <Div flex="column" gap={32}>
        <Div
          borderColor={theme.colors.primary}
          backgroundColor={theme.colors.white_1}
          style={{ padding: "40px 48px" }}
          tablet={{ style: { padding: "25px 29px" } }}
          flex="column"
          gap={40}
        >
          {!!milestone?.milestone_description && (
            <Section title={t("programs|singleMilestone.description")}>
              <Typography elementTheme="body2">
                {parse(milestone?.milestone_description || "", options)}
              </Typography>
            </Section>
          )}

          <Section title={t("programs|singleMilestone.recap")}>
            <Typography elementTheme="body2">
              {!!milestone?.milestone_recap
                ? parse(milestone?.milestone_recap || "", options)
                : t("programs|noRecap")}
            </Typography>
          </Section>

          {!!attachments.length && (
            <Section title={t("programs|singleMilestone.files")}>
              <MilestoneAttachmentsList attachments={attachments} />
            </Section>
          )}
        </Div>

        {!!admin && (
          <AdminCard
            admin={admin}
            profile={profile}
            title={t("propulsion|adminCard.textProgram")}
            subtitle={t("propulsion|adminCard.textProgram")}
          />
        )}
      </Div>
    </PageLayout>
  );
};

export default ClientSingleMilestonePage;

