import {
  CORE_NAMESPACES,
  Language,
} from "@hellodarwin/core/lib/features/entities/translations_ns";
import formatLang from "@hellodarwin/core/lib/features/helpers/format-lang";
import isBrowser from "@hellodarwin/core/lib/features/helpers/is-browser";
import {
  DEFAULT_LANGUAGE,
  LOCALE_CACHE_KEY,
  SUPPORTED_LANGUAGES,
} from "@hellodarwin/core/lib/features/providers/locale-provider";
import {
  HttpBackend,
  LanguageDetector,
  i18n,
  initReactI18next,
  resourcesToBackend,
} from "@hellodarwin/core/lib/plugins/i18n/provider";
import "dayjs/locale/fr";
const NAMESPACES = [
  "project",
  "faq",
  "propulsion",
  "resource",
  "onboarding",
  ...CORE_NAMESPACES,
];

// Initialize i18n
i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(
    resourcesToBackend(async (language: string, namespace: string) => {
      try {
        if (CORE_NAMESPACES.includes(namespace)) {
          return await require(
            `../../content/locales/${language}/${namespace}.json`
          );
        } else {
          return await require(
            `../public/locales/${language}/${namespace}.json`
          );
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    })
  )
  .init({
    ns: NAMESPACES,
    defaultNS: "common",
    lng: getLanguage(),
    fallbackLng: DEFAULT_LANGUAGE,
    nsSeparator: "|",
    react: {
      useSuspense: false,
    },
  });

// Get the user's language preference
export function getLanguage(): Language {
  if (!isBrowser) return DEFAULT_LANGUAGE;

  const storedLanguage = localStorage.getItem(LOCALE_CACHE_KEY) as Language;
  const browserLanguage = navigator.language as Language;

  return SUPPORTED_LANGUAGES.includes(storedLanguage)
    ? storedLanguage
    : formatLang(browserLanguage);
}

// Load language files for specified namespaces
export async function loadLanguageFiles(namespaces: string[]) {
  try {
    await i18n.loadNamespaces(namespaces);
  } catch (error) {
    console.error(error);
  }
}

export default i18n;

