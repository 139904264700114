/* eslint-disable react-hooks/exhaustive-deps */
import List from "@hellodarwin/core/lib/components/common/List";
import Button from "@hellodarwin/core/lib/components/common/button";
import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import ResourceCard from "@hellodarwin/core/lib/components/resources/resource-card";
import { ContentfulResource } from "@hellodarwin/core/lib/features/entities/resources-entities";
import useMediaQuery from "@hellodarwin/core/lib/features/helpers/use-media-query";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Empty from "antd/es/empty";
import Select from "antd/es/select";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/index";
import {
  getResources,
  selectFilteredResources,
  selectResourcesLoading,
  setSelectedTag,
} from "../../features/api/slices/resources-slice";
import { useContentfulApi } from "../../features/api/use-contentful-api";

const ClientResourcesPage = () => {
  const { selectedLocale } = useLocale();
  const api = useContentfulApi();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tag = searchParams.get("tag");
  const [selectedTag, setTag] = useState<string | null>(tag || null);
  const resources = useAppSelector((state) =>
    selectFilteredResources(state, selectedTag)
  );
  const resourceTags = useAppSelector((state) => state.resources.tags);
  const isLoading = useAppSelector(selectResourcesLoading);

  const navigate = useNavigate();
  const handleNavigate = (resource: ContentfulResource) => {
    navigate(`/resources/${resource.slug}`);
  };

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  useEffect(() => {
    setSelectedTag(tag);
    dispatch(setSelectedTag(tag));
  }, [tag]);

  useEffect(() => {
    dispatch(getResources({ api }));
    if (tag) {
      setTag(tag);
      dispatch(setSelectedTag(tag));
    }
  }, [tag]);

  const handleSelectedTagChange = (selectedTag: string) => {
    if (selectedTag === "-") {
      setTag(null);
      dispatch(setSelectedTag(null));
      navigate(`/resources`);
    } else {
      setTag(selectedTag);
      dispatch(setSelectedTag(selectedTag));
      navigate(`/resources?tag=${selectedTag}`);
    }
  };

  const handleBlog = () => {
    window.open("https://hellodarwin.com/blog", "_blank");
  };

  const content = useMemo(() => {
    return (
      <List
        renderEmpty={() => <Empty description={t("resource|NoResources")} />}
        grid={{
          gutter: 40,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 3,
          xxl: 4,
        }}
        dataSource={resources}
        loading={isLoading}
        renderItem={(resource) => (
          <List.Item
            style={{
              height: "calc(100% - 30px)",
              display: "flex",
              alignItems: "stretch",
              marginBottom: 30,
            }}
          >
            <ResourceCard
              {...resource}
              handleClick={() => handleNavigate(resource as ContentfulResource)}
            />
          </List.Item>
        )}
      />
    );
  }, [resources, isLoading, selectedLocale, selectedTag]);

  const BlogButton = () => {
    return (
      <Button
        onClick={handleBlog}
        defaultStyle={theme.colors.yellow_1}
        size={"small"}
      >
        {t("resource|visitBlog")}
      </Button>
    );
  };

  const SelectTag = () => {
    const sortedTags = resourceTags.slice().sort();
    return (
      <Select
        value={selectedTag ? selectedTag : "-"}
        onChange={handleSelectedTagChange}
        style={{ width: 250 }}
        defaultValue={selectedTag ? selectedTag : "-"}
        options={[
          {
            key: "-",
            label: "-",
            value: "-",
          },
          ...sortedTags.map((tag) => ({
            key: tag,
            label: tag.charAt(0).toUpperCase() + tag.slice(1),
            value: tag,
          })),
        ]}
      />
    );
  };

  return (
    <PageLayout
      app="client"
      className="resources-page"
      title={t("resource|resourcesYouCouldUse")}
      actions={
        <Div
          flex="row"
          gap={32}
          justify="center"
          align="center"
          tablet={{ style: { flexWrap: "wrap", rowGap: 16, paddingTop: 16 } }}
        >
          {isTablet ? (
            <>
              <BlogButton />
              <SelectTag />
            </>
          ) : (
            <>
              <SelectTag />
              <BlogButton />
            </>
          )}
        </Div>
      }
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("resource|resourcesYouCouldUse"),
        },
      ]}
    >
      <Container>{content}</Container>
    </PageLayout>
  );
};

export default ClientResourcesPage;

