import styled from "styled-components";
import { fadeIn, fadeOut } from "../../../../../theme/keyframes";

export const FilterProjectContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
  padding-bottom: 16px;
  animation: ${fadeIn} 200ms linear;
  &.fade-out {
    animation: ${fadeOut} 200ms linear;
    opacity: 0;
  }
`;

