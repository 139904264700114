import { WebsiteGrantResult } from "../../../../../features/entities/grants-entities";
import parse from "../../../../../features/helpers/parse";
import Div from "../../../../common/div";

const GrantOtherInfos = ({ additional_information }: WebsiteGrantResult) => {
  if (!additional_information) return <></>;
  return <Div>{parse(additional_information)}</Div>;
};

export default GrantOtherInfos;

