import { GrantProject } from "../../../../../features/entities/grants-entities";
import { useTheme } from "../../../../../plugins/styled";
import HdTag from "../../../../common/hd-tag";

interface FilterProjectTagProps {
  project: GrantProject;
  isActive?: boolean;
  handleProjectSelection: (projectId: string) => void;
}

const FilterProjectTag: React.FC<FilterProjectTagProps> = ({
  project,
  isActive,
  handleProjectSelection,
}) => {
  const theme = useTheme();
  if (!project.short_description) return <></>;

  const handleTagClick = () => {
    handleProjectSelection(project.grant_project_id);
  };
  return (
    <HdTag
      onClick={handleTagClick}
      hoverable
      text={project.short_description}
      color={isActive ? theme.colors.purple_3 : theme.colors.purple_4}
    />
  );
};
export default FilterProjectTag;

