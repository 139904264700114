import styled from "@hellodarwin/core/lib/plugins/styled";
import { fadeIn, fadeOut } from "@hellodarwin/core/lib/theme/keyframes";
export const FundingExplorerTotal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  animation: ${fadeIn} 500ms linear;
  opacity: 1;

  &.fade-out {
    animation: ${fadeOut} 200ms linear;
    opacity: 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

