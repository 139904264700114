import Div from "@hellodarwin/core/lib/components/common/div";
import HdTag from "@hellodarwin/core/lib/components/common/hd-tag";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Match } from "@hellodarwin/core/lib/features/entities";
import { getAverageAllReviews } from "@hellodarwin/core/lib/features/helpers";
import theme from "@hellodarwin/core/lib/theme";
import Chat from "@hellodarwin/icons/dist/icons/Chat";
import RatingFill from "@hellodarwin/icons/dist/icons/RatingFill";
import Avatar from "antd/es/avatar/avatar";
import Divider from "antd/es/divider";
import Tooltip from "antd/es/tooltip";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ProviderActions from "../provider-actions";
import { ProviderRowContainer } from "./styles";

type ProviderRowProps = {
  match: Match;
  hasWinner: boolean;
  isOverview?: boolean;
  isClassic?: boolean;
};

export const PROJECT_OVERVIEW_WIDTH = 235;
export const PROJECT_OVERVIEW_HEIGHT = 185;
export const PROJECT_DEFAULT_WIDTH = 250;
export const PROJECT_DEFAULT_HEIGHT = 225;

const ProviderRow = ({
  match,
  hasWinner,
  isOverview,
  isClassic,
}: ProviderRowProps) => {
  const navigate = useNavigate();

  const handleProviderClick = () => {
    navigate(`/projects/${match.rfp_id}/providers/${match.match_id}`);
  };

  const {
    provider: { reviews },
  } = match;

  const average = useMemo(() => {
    return reviews ? getAverageAllReviews(reviews) : undefined;
  }, [reviews]);

  return (
    <ProviderRowContainer
      onClick={handleProviderClick}
      $isOverview={isOverview}
    >
      <Div
        flex={"column"}
        justify={"center"}
        gap={8}
        style={{ height: "100%" }}
      >
        {!isOverview && (
          <Div fitContent position="absolute" style={{ top: 12, right: 12 }}>
            <ProviderActions
              match={match}
              hasWinner={hasWinner}
              isSecondary
              isOverview={isOverview}
              isClassic={isClassic}
            />
          </Div>
        )}
        <Div
          flex="column"
          align="center"
          justify="space-between"
          gap={19}
          style={{ height: "100%" }}
        >
          <Div flex="column" gap={15} align="center">
            <Avatar
              src={match.provider.logo}
              size={isOverview ? 56 : 62}
              shape="square"
            >
              <Typography elementTheme="h2" color={theme.colors.white_1} center>
                {match.provider.name?.[0]}
              </Typography>
            </Avatar>
            <Div flex="column" gap={5}>
              <Typography
                elementTheme="subtitle2"
                textAlign="center"
                nowrap
                ellipsis
              >
                {match.provider.name}
              </Typography>
              <Div flex="row" align="center" justify="center" gap={10}>
                {match.provider.city && (
                  <Typography
                    elementTheme="overline"
                    color={theme.colors.grey_2}
                    textAlign={"center"}
                    fitContent
                  >
                    {`${match.provider.city}, ${match.provider.province}`}
                  </Typography>
                )}
                {!!match.provider.city && average && reviews && (
                  <Divider
                    type="vertical"
                    style={{
                      margin: 0,
                      height: "15px",
                      top: 0,
                      borderColor: theme.colors.grey_4,
                    }}
                  />
                )}
                {average && reviews && (
                  <Div fitContent flex="row" align="center" gap={3.2}>
                    <RatingFill width={12} height={12} />
                    <Typography elementTheme="overline">
                      {Math.round(average * 10) / 10}
                    </Typography>
                  </Div>
                )}
              </Div>
            </Div>
            {!!match.hello_message && !isOverview && (
              <Tooltip title={match.hello_message}>
                <div style={{ overflow: "hidden", width: "100%" }}>
                  <HdTag
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      lineHeight: "14px",
                    }}
                    text={
                      <Div flex="row" gap={8} overflow="hidden">
                        <Chat
                          width={14}
                          height={14}
                          style={{ flexBasis: 14, flexShrink: 0, flexGrow: 0 }}
                        />

                        <Typography
                          nowrap
                          elementTheme="caption"
                          inline
                          overflow
                          ellipsis
                          color={theme.colors.purple_1}
                          textTransform="none"
                        >
                          {match.hello_message || ""}
                        </Typography>
                      </Div>
                    }
                  />
                </div>
              </Tooltip>
            )}
          </Div>

          <ProviderActions
            match={match}
            hasWinner={hasWinner}
            isPrimary
            isClassic={isClassic}
          />
        </Div>
      </Div>
    </ProviderRowContainer>
  );
};

export default ProviderRow;

