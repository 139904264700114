import { useState } from "react";
import { AdminProfile, Profile } from "../../features/entities";
import { useTranslations } from "../../features/providers/translations-provider";
import { useTheme } from "../../plugins/styled";
import Avatar from "../common/avatar";
import Button from "../common/button";
import Div from "../common/div";
import Typography from "../common/typography";
import ManagerContactModal from "./manager-contact-modal";

interface AdminCardProps {
  admin: AdminProfile;
  profile: Profile;
  isLoading?: boolean;
  isPropulsion?: boolean;
  title: string;
  subtitle: string;
}

const AdminCard = ({
  admin,
  title,
  subtitle,
  profile,
  isLoading,
  isPropulsion,
}: AdminCardProps) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const [isVisible, setVisible] = useState(false);
  const handleClick = () => setVisible(true);
  const handleClose = () => setVisible(false);

  return (
    <>
      <Div
        flex="row"
        tablet={{ flex: "column" }}
        style={{ minHeight: 210, maxWidth: 736 }}
        align="stretch"
        backgroundColor={theme.colors.beige_1}
        borderColor={theme.colors.primary}
      >
        <Div
          flex="column"
          align="center"
          borderColor={theme.colors.primary}
          bordersSide="right"
          tablet={{ bordersSide: "bottom", style: { width: "100%" } }}
          gap={16}
          style={{ width: 194, flexShrink: 0, padding: 24 }}
        >
          <Avatar
            size={128}
            shape="square"
            src={admin?.picture?.replace("s96", "s400") || ""}
          >
            {admin?.first_name?.[0]}
          </Avatar>

          <div>
            <Typography
              textAlign="center"
              elementTheme="subtitle2"
              nowrap
              ellipsis
              style={{ width: "100%" }}
              tablet={{ textAlign: "center" }}
            >
              {admin.first_name} {admin.last_name}
            </Typography>

            {!!admin.title && (
              <Typography
                textAlign="center"
                elementTheme="body3"
                nowrap
                style={{ width: "100%" }}
                tablet={{ textAlign: "center" }}
              >
                <i>{admin.title}</i>
              </Typography>
            )}
          </div>
        </Div>

        <Div
          style={{ padding: "24px 32px" }}
          flex="column"
          gap={24}
          tablet={{ align: "center" }}
        >
          <Div flex="column" gap={16}>
            <Typography
              elementTheme="subtitle2"
              tablet={{ textAlign: "center" }}
            >
              {title}
            </Typography>
            <Typography
              elementTheme="body2"
              color={theme.colors.grey_2}
              tablet={{ textAlign: "center" }}
            >
              {subtitle}
            </Typography>
          </Div>

          <Button
            onClick={handleClick}
            defaultStyle={theme.colors.purple_2}
            size="small"
          >
            {t("adminCard|adminCta")}
          </Button>
        </Div>
      </Div>
      <ManagerContactModal
        open={isVisible}
        admin={admin}
        handleClose={handleClose}
        profile={profile}
        isLoading={isLoading}
        infos={[
          { label: t("adminCard|adminEmail"), value: admin.email },
          { label: t("adminCard|phone"), value: admin.phone },
        ]}
      />
    </>
  );
};

export default AdminCard;

