import { DefaultOptionType } from "antd/es/select";
import { useMemo } from "react";
import { FundingExplorerFilterProps } from "..";
import { useTheme } from "../../../../../plugins/styled";
import { FundingExplorerFilterValues } from "../types";
import getFilterTagBarTheme from "./get-filter-tag-bar-theme";
import useGetOptions from "./use-get-options";

interface FilterMapping {
  [value: string]: {
    label: string;
    tagColor: string;
    type: keyof FundingExplorerFilterValues;
  };
}

const InitialFilterMapping: FilterMapping = {};

const useFilterReducer = <T>(
  data: T[],
  valueKey: keyof T,
  labelKey: keyof T,
  type: keyof FundingExplorerFilterValues
) => {
  const theme = useTheme();
  return useMemo(() => {
    return data.reduce(
      (o, value) => ({
        ...o,
        [value[valueKey] as string]: {
          label: value[labelKey] as string,
          tagColor: getFilterTagBarTheme(
            type,
            theme,
            value[valueKey] as string
          ),
          type,
        },
      }),
      InitialFilterMapping
    );
  }, [data, labelKey, valueKey]);
};

const useTagsInformations = ({
  industries,
  subIndustries,
  financingType,
  provinces,
  services,
  companySize,
  annualRevenue,
  filterValues,
  featuredProjects,
}: FundingExplorerFilterProps) => {
  const { statusOptions, filterOptions } = useGetOptions();

  const mappedIndustries = useFilterReducer(
    industries,
    "value",
    "label",
    "industry"
  );
  const mappedSubindustries = useFilterReducer(
    subIndustries,
    "value",
    "label",
    "subindustry"
  );
  const mappedFinancingType = useFilterReducer(
    financingType,
    "value",
    "label",
    "financingType"
  );
  const mappedProvinces = useFilterReducer(
    provinces,
    "value",
    "label",
    "region"
  );
  const mappedServices = useFilterReducer(
    services,
    "value",
    "label",
    "service"
  );
  const mappedProjects = useFilterReducer(
    featuredProjects ?? [],
    "grant_project_id",
    "short_description",
    "selectedProjects"
  );
  const mappedCompanySizes = useFilterReducer(
    companySize,
    "value",
    "label",
    "companySize"
  );
  const mappedAnnualRevenues = useFilterReducer(
    annualRevenue,
    "value",
    "label",
    "annualRevenue"
  );
  const mappedStatus = useFilterReducer<DefaultOptionType>(
    statusOptions,
    "value",
    "label",
    "status"
  );

  const allTagsInformations = useMemo(() => {
    return {
      ...mappedIndustries,
      ...mappedSubindustries,
      ...mappedAnnualRevenues,
      ...mappedCompanySizes,
      ...mappedProjects,
      ...mappedServices,
      ...mappedProvinces,
      ...mappedFinancingType,
      ...mappedStatus,
    };
  }, [
    mappedAnnualRevenues,
    mappedCompanySizes,
    mappedProjects,
    mappedServices,
    mappedProvinces,
    mappedFinancingType,
    mappedIndustries,
    mappedSubindustries,
    mappedStatus,
  ]);

  const allTags = useMemo(() => {
    if (!filterValues) return [];
    return [
      ...(filterValues.industry ?? []),
      ...(filterValues.subindustry ?? []),
      ...(filterValues.financingType ?? []),
      ...(filterValues.region ?? []),
      ...(filterValues.service ?? []),
      ...(filterValues.status ?? []),
      ...(filterValues.selectedProjects ?? []),
      ...(filterValues.companySize ? [filterValues.companySize] : []),
      ...(filterValues.annualRevenue ? [filterValues.annualRevenue] : []),
    ];
  }, [filterValues]);

  return useMemo(
    () => ({ allTags, allTagsInformations }),
    [allTagsInformations, allTags]
  );
};

export default useTagsInformations;

