import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { GrantTotalAmounts } from "@hellodarwin/core/lib/features/entities/grants-entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import CountUp from "react-countup";

interface GrantsSiderProps {
  grantsTotals: GrantTotalAmounts[];
  isLoading: boolean;
  inversed?: boolean;
}

const RoadmapDashboardSectionBigTotalCount = ({
  grantsTotals,
  isLoading,
  inversed,
}: GrantsSiderProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  if (isLoading) return <></>;

  return (
    <Div flex="row">
      <Div flex="row" justify="left">
        <Typography
          ellipsis
          overflow
          elementTheme="subtitle2"
          color={inversed ? theme.colors.white_1 : theme.colors.green_1}
          textAlign="right"
        >
          <CountUp
            end={grantsTotals[0]?.total_funding}
            separator={t(`grants|grantsTotal.seperatorTotal`)}
            prefix={t(`grants|grantsTotal.prefixTotal`)}
            suffix={t(`grants|grantsTotal.suffixTotal`)}
            duration={1}
          />
        </Typography>
      </Div>
    </Div>
  );
};

export default RoadmapDashboardSectionBigTotalCount;

