import { ApiError } from "@hellodarwin/core/lib/features/entities/api-entitites";
import { createAsyncThunk } from "@reduxjs/toolkit";
import showErrorNotification from "../utils/show-error-notifications";

const SliceRequest = <T1, T2>(
  name: string,
  func: (props: T2) => Promise<T1>
) => {
  return createAsyncThunk<T1, T2, { rejectValue: ApiError }>(
    `admin/${name}`,
    (payload: T2, { rejectWithValue }) => {
      try {
        return func(payload).catch((err) => {
          console.error(err.response.data);
          showErrorNotification(err.response.data.error_code);
          return rejectWithValue(err.response.data);
        });
      } catch (err: any) {
        console.error(err);
        showErrorNotification(err.response.data.error_code);
        return rejectWithValue(err.response.data);
      }
    }
  );
};

export default SliceRequest;

