import Divider from "antd/es/divider";
import { TabsProps } from "antd/es/tabs";
import { Project } from "../../../../features/entities/project-entities";
import { getStringDate } from "../../../../features/helpers/get-formatted-date";
import getShortId from "../../../../features/helpers/get-short-id";
import useMediaQuery from "../../../../features/helpers/use-media-query";
import { useTranslation } from "../../../../plugins/i18n";
import { useTheme } from "../../../../plugins/styled";
import { DefaultTheme } from "../../../../theme";
import Div from "../../../common/div";
import Typography from "../../../common/typography";
import ProjectMenu from "./project-menu";
import ProjectMenuMobile from "./project-menu-mobile/index";
type ProjectHeaderProps = {
  project: Project;
  tabs: TabsProps["items"];
  activeTab: string;
  handleTabClick: (tabKey: string) => void;
  locale: string;
};
const ProjectHeader = ({
  project,
  tabs,
  activeTab,
  handleTabClick,
  locale,
}: ProjectHeaderProps) => {
  const { t } = useTranslation("project");
  const theme = useTheme() as DefaultTheme;
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  return (
    <Div flex="column" gap={22}>
      <Div flex="row" justify="space-between" align="center" gap={32}>
        <Div flex="column" gap={15}>
          <Typography.Title level={1} elementTheme="h6">
            {project.title && project.title?.length > 0
              ? project.title
              : project.service
                ? project.service
                : `${t("project|project")} ${getShortId(project.rfp_id)}`}
          </Typography.Title>
          <Div flex="row" align="stretch" gap={20}>
            <Typography elementTheme="overline" color={theme.colors.grey_3}>
              {t("project|projectTab.projectDetails.projectId").toUpperCase()} :{" "}
              {getShortId(project.rfp_id)}
            </Typography>
            <Divider
              type="vertical"
              style={{ margin: 0, height: "auto", top: 0 }}
            />
            <Typography elementTheme="overline" color={theme.colors.grey_3}>
              {t("project|createdAt").toUpperCase()}{" "}
              {getStringDate(new Date(project.created_at), locale)
                .toString()
                .toUpperCase()}
            </Typography>
          </Div>
        </Div>
      </Div>
      {isTablet ? (
        <ProjectMenuMobile
          tabs={tabs}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
        />
      ) : (
        <ProjectMenu
          tabs={tabs}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
        />
      )}
    </Div>
  );
};

export default ProjectHeader;

