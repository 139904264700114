import Button from "@hellodarwin/core/lib/components/common/button";
import ModalLayoutWithIcon from "@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-icon";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { unwrapResult } from "@reduxjs/toolkit";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";

import theme from "@hellodarwin/core/lib/theme";
import MoreCandidates from "@hellodarwin/icons/dist/icons/MoreCandidates";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import {
  selectAskMoreProviderMessage,
  setAskMoreProviderMessage,
} from "../../../features/api/slices/global-slice";
import {
  askMoreProviderProject,
  selectProjectById,
  selectSelectedProjectId,
} from "../../../features/api/slices/projects-slice";
import { useClientApi } from "../../../features/api/use-client-api";
import ErrorModal from "../../provider/provider-action-modal/error-modal";
type ProjectMoreCandidatesModalProps = {
  open: boolean;
  handleClose: () => void;
};

const ProjectMoreCandidatesModal = ({
  open,
  handleClose,
}: ProjectMoreCandidatesModalProps) => {
  const { t } = useTranslation();
  const api = useClientApi();
  const dispatch = useAppDispatch();

  const projectId = useAppSelector(selectSelectedProjectId) || "";
  const project = useAppSelector((state) =>
    selectProjectById(state, projectId)
  );

  const [error, setError] = useState<number>();
  const askMoreProviderMessage = useAppSelector(selectAskMoreProviderMessage);

  const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    dispatch(setAskMoreProviderMessage(e.currentTarget.value));
  };

  if (!project) return <></>;

  const handleConfirm = () => {
    (async () => {
      const projectId = project.rfp_id;

      await dispatch(
        askMoreProviderProject({ api, projectId, askMoreProviderMessage })
      )
        .then(unwrapResult)
        .catch((err: any) => {
          handleError(err.response.data.error_code);
        });

      if (!!handleClose) {
        dispatch(setAskMoreProviderMessage(""));
        handleClose();
      }
    })();
  };

  const handleError = (errorCode: number) => {
    setError(errorCode);
  };
  const closeError = () => {
    setError(undefined);
  };

  if (!!error)
    return (
      <ErrorModal error={error} isVisible={!!error} onCancel={closeError} />
    );

  return (
    <ModalLayoutWithIcon
      open={open}
      handleCancel={handleClose}
      size="medium"
      Icon={MoreCandidates}
      iconSize={64}
      title={t("project|modal.moreCandidates.title")}
      description={t("project|modal.moreCandidates.text", {
        raiseHandMax: project.raise_hand_max,
      })}
      Actions={
        <>
          <Button
            style={{ flex: 1 }}
            defaultStyle={theme.colors.white_1}
            onClick={handleClose}
            size="small"
          >
            {t("button.cancel")}
          </Button>
          <Button
            defaultStyle={theme.colors.purple_1}
            style={{ flex: 1 }}
            onClick={handleConfirm}
            size="small"
          >
            {t(`project|modal.moreCandidates.confirm`)}
          </Button>
        </>
      }
    >
      <TextArea
        placeholder={t(`project|modal.moreCandidates.placeHolder`)}
        rows={7}
        value={askMoreProviderMessage}
        onChange={handleChange}
        maxLength={300}
      />
    </ModalLayoutWithIcon>
  );
};

export default ProjectMoreCandidatesModal;

