import { Company } from "../../../../features/entities";
import isEmptyStringOrArray from "../../../../features/helpers/is-empty-string-or-array";
import { FundingExplorerFilterValues } from "../filter/types";

interface CheckCompanyFiltersProps {
  company: Company;
  filterValues: FundingExplorerFilterValues;
}

const compareValues = (
  value1: string | string[] | undefined,
  value2: string | string[] | undefined
) => {
  if (Array.isArray(value1) !== Array.isArray(value2)) return false;

  if (Array.isArray(value1)) {
    if (value1?.length !== value2?.length) return false;

    return value1?.every((element) => value2?.includes(element));
  } else {
    return value1 ? value2 === value1 : !value2;
  }
};

const checkCompanyIsInFilter = ({
  company,
  filterValues,
}: CheckCompanyFiltersProps) => {
  if (!company) return false;
  if (!Object.keys(filterValues).length) return false;

  const {
    annualRevenue,
    industry: industries,
    subindustry: subindustries,
    region,
    companySize,
    service,

    status,
    financingType,
  } = filterValues;

  if (
    !annualRevenue?.length &&
    !industries?.length &&
    !subindustries?.length &&
    !region?.length &&
    !companySize?.length
  ) {
    return false;
  }

  const industryMatch =
    isEmptyStringOrArray(industries) &&
    isEmptyStringOrArray(company.industry_sector)
      ? true
      : compareValues(company.industry_sector, industries);

  const subindustryMatch = compareValues(
    company.industry_subsector,
    subindustries
  );

  const regionMatch = !!region.length ? region[0] === company.province : true;

  const annualRevenueMatch = compareValues(
    company.annual_revenue,
    annualRevenue
  );

  const companySizeMatch = compareValues(company.size, companySize);
  const serviceMatch = !service?.length;
  const financingTypeMatch = !financingType?.length;
  const statusMatch = !status?.length;

  if (
    industryMatch &&
    subindustryMatch &&
    statusMatch &&
    financingTypeMatch &&
    regionMatch &&
    serviceMatch &&
    annualRevenueMatch &&
    companySizeMatch
  ) {
    return true;
  } else {
    return false;
  }
};

export default checkCompanyIsInFilter;

