import Typography from "@hellodarwin/core/lib/components/common/typography";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import React, { useMemo } from "react";
import CountUp from "react-countup";
import { useAppSelector } from "../../app/app-hooks";
import useFundingExplorerLoading from "../../components/funding-explorer/utils/use-funding-explorer-loading";
import {
  selectAllFundingExplorerGrants,
  selectFundingExplorerTotalAmount,
} from "../../features/api/slices/grants-slice";
import { FundingExplorerTotal } from "./styles";

const GrantsDirectoryTotal: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isFundingExplorerLoading = useFundingExplorerLoading();
  const grants = useAppSelector(selectAllFundingExplorerGrants);
  const totalAmount = useAppSelector(selectFundingExplorerTotalAmount);

  const hasGrants = useMemo(
    () => grants !== undefined && grants?.length > 0,
    [grants]
  );

  if (isFundingExplorerLoading.total) return <></>;
  return (
    <FundingExplorerTotal
      className={
        isFundingExplorerLoading.amount || isFundingExplorerLoading.query
          ? "fade-out"
          : undefined
      }
    >
      {!hasGrants ? (
        <Typography elementTheme="h6" style={{ fontWeight: 400 }} nowrap>
          {t(`grants|noGrants`)}
        </Typography>
      ) : (
        <>
          <Typography
            elementTheme="h6"
            nowrap
            color={theme.colors.purple_1}
            tablet={{ elementTheme: "subtitle1" }}
          >
            <CountUp
              end={grants[0]?.total_count ?? 0}
              separator=" "
              duration={1}
            />
          </Typography>
          <Typography
            elementTheme="h6"
            style={{ fontWeight: 400 }}
            nowrap
            tablet={{ elementTheme: "subtitle1" }}
          >
            {t(`grants|grants`)}{" "}
            {totalAmount !== undefined &&
              totalAmount !== 0 &&
              t(`grants|grantsTotal.totaling`)}
          </Typography>

          <Typography
            elementTheme="h6"
            nowrap
            color={theme.colors.purple_1}
            tablet={{ elementTheme: "subtitle1" }}
          >
            {totalAmount > 0 && (
              <CountUp
                end={totalAmount}
                separator={t(`grants|grantsTotal.seperatorTotal`)}
                prefix={t(`grants|grantsTotal.prefixTotal`)}
                suffix={t(`grants|grantsTotal.suffixTotal`)}
                duration={1}
              />
            )}
          </Typography>
        </>
      )}
    </FundingExplorerTotal>
  );
};

export default GrantsDirectoryTotal;

