import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import { useTranslation } from "../../../../../plugins/i18n";
import Collapse from "../../../../common/Collapse";
import Typography from "../../../../common/typography";
import FilterCompanyCardContent, {
  FilterCompanyCardContentProps,
} from "./filter-company-card-content";

interface FilterCompanyCardProps extends FilterCompanyCardContentProps {
  showFilterCompanyCard?: boolean;
}
const FilterCompanyCard: React.FC<FilterCompanyCardProps> = (props) => {
  const { showFilterCompanyCard, company } = props;
  const { t } = useTranslation();

  if (!showFilterCompanyCard || !company.company_id.length) return <></>;

  return (
    <Collapse
      ExpandIcon={(props) => (
        <TabArrow down={props.isActive} size={14} style={{ marginRight: 16 }} />
      )}
      noPadding
      items={[
        {
          title: (
            <Typography style={{ padding: "4px 16px" }} elementTheme="body2">
              {t("grants|grantFilterCard.intro")}
            </Typography>
          ),
          children: <FilterCompanyCardContent {...props} />,
          id: "filtercompany-card",
        },
      ]}
    />
  );
};

export default FilterCompanyCard;

