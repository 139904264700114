import { IconNode } from "@hellodarwin/icons/dist/features/entities/general";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";

const InfoTile = ({ value, Icon }: { value?: string; Icon: IconNode }) => {
  if (!value?.length) return <></>;
  return (
    <Div flex="row" gap={24} align="center">
      <Icon size={18} style={{ flexGrow: 0, flexShrink: 0 }} />
      <Typography>{value}</Typography>
    </Div>
  );
};

export default InfoTile;

