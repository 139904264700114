import { usePagination } from "@hellodarwin/core/lib/features/providers/pagination-provider";
import { useMemo } from "react";
import { useAppSelector } from "../../../app/app-hooks";
import { selectGrantsIsLoading } from "../../../features/api/slices/grants-slice";
import { selectProfileLoading } from "../../../features/api/slices/profile-slice";
import { selectTagsIsLoading } from "../../../features/api/slices/tags-slice";

const useFundingExplorerLoading = () => {
  const isCompanyLoading = useAppSelector(selectProfileLoading);
  const isFinancingTypeLoading = useAppSelector((state) =>
    selectGrantsIsLoading(state, "fetchGrantFinancingType")
  );
  const isProvincesLoading = useAppSelector((state) =>
    selectGrantsIsLoading(state, "fetchProvinces")
  );
  const isGrantServicesLoading = useAppSelector((state) =>
    selectGrantsIsLoading(state, "fetchGrantServices")
  );
  const isIndustriesLoading = useAppSelector((state) =>
    selectTagsIsLoading(state, "fetchIndustries")
  );
  const isQueryLoading = useAppSelector((state) =>
    selectGrantsIsLoading(state, "queryFundingExplorer")
  );

  const isProjectsLoading = useAppSelector((state) =>
    selectGrantsIsLoading(state, "fetchGrantProjects")
  );
  const isTotalAmountLoading = useAppSelector((state) =>
    selectGrantsIsLoading(state, "fetchFundingExplorerTotalAmount")
  );
  const { paramsIsLoading } = usePagination();

  return useMemo(() => {
    const total =
      isCompanyLoading ||
      isFinancingTypeLoading ||
      isProvincesLoading ||
      isGrantServicesLoading ||
      isProjectsLoading ||
      isQueryLoading ||
      paramsIsLoading ||
      isTotalAmountLoading ||
      isIndustriesLoading;
    const amount = isTotalAmountLoading;
    const company = isCompanyLoading;
    const filters =
      isFinancingTypeLoading ||
      isProvincesLoading ||
      isGrantServicesLoading ||
      isIndustriesLoading;
    const query = isQueryLoading;
    const projects = isProjectsLoading;
    const url = paramsIsLoading;

    return { total, company, filters, query, projects, amount, url };
  }, [
    isCompanyLoading,
    isFinancingTypeLoading,
    isProvincesLoading,
    isGrantServicesLoading,
    isIndustriesLoading,
    isQueryLoading,
    isTotalAmountLoading,
    isProjectsLoading,
    paramsIsLoading,
  ]);
};

export default useFundingExplorerLoading;

