import {
  AdminGrantResult,
  ApiError,
  ApiErrorInitialState,
  ApiFunctionsStatus,
  FormsSubmissionPreview,
  GrantFinancingType,
  GrantInitialState,
  GrantProject,
  GrantResult,
  GrantService,
  GrantTotalAmounts,
  Province,
  TranslatedFormResponse,
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/app-store";
import showErrorNotification from "../../utils/show-error-notifications";
import ClientApi from "../client-api";
import { QueryFundingExplorerProps } from "../client-api-entities";

export const fetchGrant = createAsyncThunk<
  GrantResult,
  { api: ClientApi; grantId: string; locale: string },
  { rejectValue: ApiError }
>(
  "client/fetchGrant",
  async (
    {
      api,
      grantId,
      locale,
    }: { api: ClientApi; grantId: string; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrant(grantId, locale);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantFinancingType = createAsyncThunk<
  GrantFinancingType[],
  { api: ClientApi; locale: string },
  { rejectValue: ApiError }
>(
  "client/fetchGrantFinancingType",
  async (
    { api, locale }: { api: ClientApi; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrantFinancingType(locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchProvinces = createAsyncThunk<
  Province[],
  { api: ClientApi; locale: string },
  { rejectValue: ApiError }
>(
  "client/fetchProvinces",
  async (
    { api, locale }: { api: ClientApi; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchProvinces(locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantServices = createAsyncThunk<
  GrantService[],
  { api: ClientApi; locale: string },
  { rejectValue: ApiError }
>(
  "client/fetchGrantServices",
  async (
    { api, locale }: { api: ClientApi; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrantService(locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantsTotals = createAsyncThunk<
  GrantTotalAmounts[],
  { api: ClientApi },
  { rejectValue: ApiError }
>(
  "client/fetchGrantsTotals",
  async ({ api }: { api: ClientApi }, { rejectWithValue }) => {
    try {
      return await api.fetchGrantsTotals();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantQualificationForm = createAsyncThunk<
  TranslatedFormResponse | undefined,
  { api: ClientApi; grant_id: string; locale: string },
  { rejectValue: ApiError }
>(
  "client/fetchGrantQualificationForm",
  async (
    {
      api,
      grant_id,
      locale,
    }: { api: ClientApi; grant_id: string; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrantQualificationForm(grant_id, locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);
export const fetchFormSubmissionByGrantId = createAsyncThunk<
  FormsSubmissionPreview[],
  { api: ClientApi; grant_id: string },
  { rejectValue: ApiError }
>(
  "client/fetchFormSubmissionByGrantId",
  async (
    { api, grant_id }: { api: ClientApi; grant_id: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchFormSubmissionByGrantId(grant_id);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);
export const queryFundingExplorer = createAsyncThunk<
  GrantResult[],
  {
    api: ClientApi;
  } & QueryFundingExplorerProps,
  { rejectValue: ApiError }
>(
  "client/queryFundingExplorer",
  async (
    {
      api,
      ...props
    }: {
      api: ClientApi;
    } & QueryFundingExplorerProps,
    { rejectWithValue }
  ) => {
    try {
      return await api.queryFundingExplorer(props);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);
export const fetchFundingExplorerTotalAmount = createAsyncThunk<
  number,
  {
    api: ClientApi;
  } & QueryFundingExplorerProps,
  { rejectValue: ApiError }
>(
  "client/fetchFundingExplorerTotalAmount",
  async (
    {
      api,
      ...props
    }: {
      api: ClientApi;
    } & QueryFundingExplorerProps,
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchFundingExplorerTotalAmount(props);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const grantsResultAdapter = createEntityAdapter({
  selectId: (model: GrantResult) => model.grant_id,
});

const adminGrantsAdapter = createEntityAdapter({
  selectId: (model: AdminGrantResult) => model.grant_id,
});

const grantProjectsAdapter = createEntityAdapter({
  selectId: (model: GrantProject) => model.grant_project_id,
});

export interface GrantState {
  status: ApiFunctionsStatus;

  error: ApiError;
  grantsSearch: EntityState<GrantResult, string>;
  grantsSearchTotal: number;
  selectedGrant: GrantResult;
  grant: EntityState<AdminGrantResult, string>;
  grantFinancingType: GrantFinancingType[];
  provinces: Province[];
  services: GrantService[];
  grantTotals: GrantTotalAmounts[];
  bestGrantProjects: EntityState<GrantProject, string>;
  activeForm: TranslatedFormResponse | undefined;
  activeFormSubmissions: FormsSubmissionPreview[];
}

const initialState: GrantState = {
  status: {
    general: "idle",
  },

  error: ApiErrorInitialState,
  grantsSearch: grantsResultAdapter.getInitialState(),
  grantsSearchTotal: 0,
  selectedGrant: GrantInitialState,
  grant: grantsResultAdapter.getInitialState(),
  grantFinancingType: [],
  provinces: [],
  services: [],
  grantTotals: [],
  bestGrantProjects: grantProjectsAdapter.getInitialState(),
  activeForm: undefined,
  activeFormSubmissions: [],
};

const grantsSlice = createSlice({
  name: "grants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGrant.pending, (state) => {
      state.status["fetchGrant"] = "pending";
    });
    builder.addCase(fetchGrant.fulfilled, (state, { payload }) => {
      adminGrantsAdapter.upsertOne(state.grantsSearch, payload);
      state.status["fetchGrant"] = "idle";
    });
    builder.addCase(fetchGrant.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status["fetchGrant"] = "idle";
    });
    builder.addCase(fetchGrantFinancingType.pending, (state) => {
      state.status["fetchGrantFinancingType"] = "pending";
    });
    builder.addCase(fetchGrantFinancingType.fulfilled, (state, { payload }) => {
      state.grantFinancingType = payload ?? [];
      state.status["fetchGrantFinancingType"] = "idle";
    });
    builder.addCase(fetchGrantFinancingType.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status["fetchGrantFinancingType"] = "idle";
    });
    builder.addCase(fetchProvinces.pending, (state) => {
      state.status["fetchProvinces"] = "pending";
    });
    builder.addCase(fetchProvinces.fulfilled, (state, { payload }) => {
      state.provinces = payload;
      state.status["fetchProvinces"] = "idle";
    });
    builder.addCase(fetchProvinces.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status["fetchProvinces"] = "idle";
    });
    builder.addCase(fetchGrantServices.pending, (state) => {
      state.status["fetchGrantServices"] = "pending";
    });
    builder.addCase(fetchGrantServices.fulfilled, (state, { payload }) => {
      state.services = payload;
      state.status["fetchGrantServices"] = "idle";
    });
    builder.addCase(fetchGrantServices.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status["fetchGrantServices"] = "idle";
    });
    builder.addCase(fetchGrantsTotals.pending, (state) => {
      state.status["fetchGrantsTotals"] = "pending";
    });
    builder.addCase(fetchGrantsTotals.fulfilled, (state, { payload }) => {
      state.grantTotals = payload;
      state.status["fetchGrantsTotals"] = "idle";
    });
    builder.addCase(fetchGrantsTotals.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status["fetchGrantsTotals"] = "idle";
    });
    builder.addCase(queryFundingExplorer.pending, (state) => {
      state.status["queryFundingExplorer"] = "pending";
    });
    builder.addCase(queryFundingExplorer.fulfilled, (state, { payload }) => {
      state.grantsSearch = grantsResultAdapter.setAll(
        state.grantsSearch,
        payload ? payload : []
      );

      state.status["queryFundingExplorer"] = "idle";
    });
    builder.addCase(queryFundingExplorer.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status["queryFundingExplorer"] = "idle";
    });
    builder.addCase(fetchFundingExplorerTotalAmount.pending, (state) => {
      state.status["fetchFundingExplorerTotalAmount"] = "pending";
    });
    builder.addCase(
      fetchFundingExplorerTotalAmount.fulfilled,
      (state, { payload }) => {
        state.grantsSearchTotal = payload;
        state.status["fetchFundingExplorerTotalAmount"] = "idle";
      }
    );
    builder.addCase(
      fetchFundingExplorerTotalAmount.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status["fetchFundingExplorerTotalAmount"] = "idle";
      }
    );

    builder.addCase(fetchGrantQualificationForm.pending, (state) => {
      state.status["fetchGrantQualificationForm"] = "pending";
    });
    builder.addCase(
      fetchGrantQualificationForm.fulfilled,
      (state, { payload }) => {
        state.activeForm = payload;
        state.status["fetchGrantQualificationForm"] = "idle";
      }
    );
    builder.addCase(
      fetchGrantQualificationForm.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status["fetchGrantQualificationForm"] = "idle";
      }
    );
    builder.addCase(fetchFormSubmissionByGrantId.pending, (state) => {
      state.status["fetchFormSubmissionByGrantId"] = "pending";
    });
    builder.addCase(
      fetchFormSubmissionByGrantId.fulfilled,
      (state, { payload }) => {
        state.activeFormSubmissions = payload;
        state.status["fetchFormSubmissionByGrantId"] = "idle";
      }
    );
    builder.addCase(
      fetchFormSubmissionByGrantId.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status["fetchFormSubmissionByGrantId"] = "idle";
      }
    );
  },
});

export const selectGrantsIsLoading = createSelector(
  [
    (state: RootState, _?: string) => state.grants.status,
    (_: RootState, functionName?: string) => functionName,
  ],
  (status, functionName) => {
    if (functionName) {
      return status[functionName] === "pending";
    }
    return !!Object.keys(status).find((s) => s === "pending");
  }
);

export const selectFundingExplorerTotalAmount = (state: RootState) =>
  state.grants.grantsSearchTotal;

export const {
  selectById: selectGrantById,
  selectAll: selectAllFundingExplorerGrants,
} = grantsResultAdapter.getSelectors(
  (state: RootState) => state.grants.grantsSearch
);

export const selectGrantFinancingType = createSelector(
  [(state) => state.grants.grantFinancingType],
  (financingType: GrantFinancingType[]) =>
    financingType?.map((item) => {
      return {
        label: item.type,
        value: item.grant_financing_type_id,
      };
    })
);

export const selectProvinces = createSelector(
  [(state) => state.grants.provinces],
  (provinces: Province[]) =>
    provinces?.map((item) => {
      return {
        label: item.name,
        value: item.code,
      };
    })
);
export const selectGrantService = createSelector(
  [(state) => state.grants.services],
  (services: GrantService[]) =>
    services?.map((item) => {
      return {
        label: item.name,
        value: item.grant_service_id,
      };
    })
);

export const selectGrantsTotals = (state: RootState) =>
  state.grants.grantTotals;

export const selectGrantsTotalsLoading = (state: RootState) =>
  state.projects.status === "pending";

export const selectActiveForm = (state: RootState) => state.grants.activeForm;
export const selectActiveFormSubmissions = (state: RootState) =>
  state.grants.activeFormSubmissions;

export const grantsReducer = grantsSlice.reducer;

