import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Loading from "@hellodarwin/core/lib/components/loading";
import ProgramSingle, {
  ProgramSingleTabs,
} from "@hellodarwin/core/lib/components/programs/single";
import {
  AssetEntity,
  AssetRecordInformation,
} from "@hellodarwin/core/lib/features/entities/assets-entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import { RootState } from "../../../app/app-store";
import {
  downloadAsset,
  fetchCompanyRecordAssets,
  selectAllRecordAssets,
  uploadAsset,
} from "../../../features/api/slices/assets-slice";
import {
  fetchAdmin,
  selectAdminByAdminId,
} from "../../../features/api/slices/global-slice";
import { selectProfile } from "../../../features/api/slices/profile-slice";
import {
  GetProgram,
  fetchGrant,
  selectGrantById,
  selectProgramById,
} from "../../../features/api/slices/programs-slice";
import {
  useClientApi,
  useNewClientApi,
} from "../../../features/api/use-client-api";

interface ClientSingleRoadmapProgramProps {
  defaultSection: ProgramSingleTabs;
}

const ClientSingleRoadmapProgram: React.FC<ClientSingleRoadmapProgramProps> = ({
  defaultSection,
}) => {
  const dispatch = useAppDispatch();
  const api = useClientApi();
  const newApi = useNewClientApi();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { programId } = useParams<{
    programId: string;
  }>();

  const profile = useAppSelector(selectProfile);
  const program = useAppSelector((state) =>
    selectProgramById(state, programId || "")
  );

  const { selectedLocale } = useLocale();

  const activeRecord: AssetRecordInformation = useMemo(
    () => ({
      record_id: program?.program_id ?? "",
      record_type: "application",
      record_name: program?.program_name,
    }),
    [program]
  );

  useEffect(() => {
    dispatch(
      GetProgram({ api, programId: programId || "", locale: selectedLocale })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId, selectedLocale]);

  useEffect(() => {
    dispatch(
      fetchCompanyRecordAssets({
        api: newApi,
        record: activeRecord,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRecord]);

  const adminId = useMemo(
    () => program?.program_account_manager || "",
    [program]
  );
  const admin = useAppSelector((state) => selectAdminByAdminId(state, adminId));
  const files = useAppSelector((state) =>
    selectAllRecordAssets(state, programId ?? "")
  );

  useEffect(() => {
    dispatch(fetchAdmin({ api, adminId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminId]);

  const grant = useAppSelector((state: RootState) =>
    selectGrantById(state, program?.program_grant_id || "")
  );

  useEffect(() => {
    if (!!program && program?.program_grant_id) {
      dispatch(
        fetchGrant({
          api,
          grantId: `${program?.program_grant_id}`,
          locale: selectedLocale,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program, selectedLocale]);

  const goBack = () => {
    navigate(`/programs/roadmap`);
  };

  const handleDownload = async (asset: AssetEntity) => {
    try {
      const url = (
        await dispatch(
          downloadAsset({ api: newApi, asset_id: asset.asset_id })
        ).unwrap()
      ).link;

      window.open(url, "download");
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveAsset = async (
    formData: FormData
  ): Promise<"rejected" | "fulfilled"> =>
    (
      await dispatch(
        uploadAsset({
          api: newApi,
          formData,
        })
      )
    ).meta.requestStatus;

  const handleTabChange = (newTab: string) => {
    if (newTab === "files") {
      navigate(`/programs/${programId}/files`);
    } else {
      navigate(`/programs/${programId}`);
    }
  };
  if (!program) return <Loading />;

  return (
    <PageLayout
      handleBack={goBack}
      app="client"
      title={program?.program_name}
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.programs"),
          path: "/programs",
        },
        {
          breadcrumbName: t("navigation.ongoingPrograms"),
          path: "/programs/roadmap",
        },
        {
          breadcrumbName: program?.program_name,
        },
      ]}
    >
      <ProgramSingle
        {...program}
        grant={grant}
        admin={admin}
        profile={profile}
        handleTabChange={handleTabChange}
        activeTab={defaultSection}
        fileList={{
          vaultPath: `/programs/roadmap/${programId}/files`,
          handleDownload,
          activeRecord,
          items: files,
          handleSaveAsset,
          rootRecord: activeRecord,
          allowUpload: true,
          title: program.program_name,
        }}
      />
    </PageLayout>
  );
};

export default ClientSingleRoadmapProgram;

