import Industry from "@hellodarwin/icons/dist/icons/Industry";
import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import Checkbox from "antd/es/checkbox";
import { DefaultOptionType, SelectProps } from "antd/es/select";
import { CSSProperties, useCallback, useMemo } from "react";
import { FundingExplorerFilterProps } from "..";
import { useTranslation } from "../../../../../plugins/i18n";
import { useTheme } from "../../../../../plugins/styled";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";
import FormItem from "../../../../forms/form-layouts/form-item";
import { FilterSelect, FilterSelectDropdown } from "../styles";

const IndustriesSelector: React.FC<FundingExplorerFilterProps> = ({
  industries,
  filterValues,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const dropdownStyle: CSSProperties = useMemo(
    () => ({
      padding: 0,
      borderRadius: 0,
      border: `1px solid ${theme.colors.primary}`,
    }),
    [theme]
  );

  const dropdownRender: SelectProps["dropdownRender"] = useCallback(
    (el: React.ReactElement) => (
      <FilterSelectDropdown>{el}</FilterSelectDropdown>
    ),
    []
  );

  const optionRender: SelectProps["optionRender"] = useCallback(
    (
      option: DefaultOptionType,
      info: {
        index: number;
      }
    ) => {
      return (
        <Div
          flex="row"
          gap={8}
          align="center"
          borderColor={theme.colors.grey_4}
          bordersSide="bottom"
          justify="space-between"
          style={{ padding: "12px 16px" }}
        >
          <Typography
            elementTheme="body3"
            color={theme.colors.primary}
            ellipsis
            overflow
            nowrap
          >
            {option.label}
          </Typography>
          <Checkbox
            type="square"
            checked={filterValues?.industry?.includes(
              option.value?.toString() ?? ""
            )}
          />
        </Div>
      );
    },
    [filterValues]
  );

  return (
    <FormItem name="industry" style={{ flex: 1, margin: 0 }}>
      <FilterSelect
        mode="multiple"
        dropdownRender={dropdownRender}
        style={{
          width: 340,
          maxWidth: "100%",
          height: 45,
        }}
        filterOption={(input, option) =>
          typeof option?.label === "string"
            ? option?.label?.toLowerCase().indexOf(input?.toLowerCase()) !== -1
            : false
        }
        suffixIcon={
          <Div
            flex="row"
            gap={32}
            align="center"
            justify="space-between"
            xl={{ gap: 8 }}
            overflow="hidden"
          >
            <Div flex="row" gap={8} align="center" style={{ maxWidth: "100%" }}>
              <Industry
                size={20}
                color={theme.colors.grey_3}
                style={{ flexShrink: 0 }}
              />
              <Typography style={{ fontSize: 15 }} nowrap ellipsis overflow>
                {t(`grants|fundingExplorerFilter.industryMainPlaceholder`)}
              </Typography>
            </Div>
            <TabArrow down size={16} />
          </Div>
        }
        size="large"
        options={industries}
        dropdownStyle={dropdownStyle}
        optionRender={optionRender}
        menuItemSelectedIcon={<></>}
      />
    </FormItem>
  );
};

export default IndustriesSelector;

