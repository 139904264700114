import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import {
  ApiError,
  ApiErrorInitialState,
  ClientOnBoardResponse,
  ClientOnboardRequest,
  Company,
  CompanyInitialState,
  Contact,
  ContactInitialState,
  Profile,
  ProfileInitialState,
  ReviewResult,
} from "@hellodarwin/core/lib/features/entities";
import SingleLanguage from "@hellodarwin/core/lib/features/enums/single-language";
import { UTMParamsType } from "@hellodarwin/core/lib/features/providers/utm-provider";
import { RootState } from "../../../app/app-store";
import showErrorNotification from "../../utils/show-error-notifications";
import ClientApi from "../client-api";

const usersAdapter = createEntityAdapter({
  selectId: (model: Contact) => model.contact_id,
});

export interface ProjectsState {
  status: {
    hello: "idle" | "pending";
    general: "idle" | "pending";
    assets: "idle" | "pending";
  };
  error: ApiError;
  profile: Profile;
  reviews: ReviewResult[];
  company: Company;
  contact: Contact;
  users: EntityState<Contact, string>;
}

const initialState: ProjectsState = {
  status: {
    hello: "idle",
    general: "idle",
    assets: "idle",
  },
  error: ApiErrorInitialState,
  profile: ProfileInitialState,
  reviews: [],
  company: CompanyInitialState,
  contact: ContactInitialState,
  users: usersAdapter.getInitialState(),
};

export const me = createAsyncThunk<
  Profile,
  { api: ClientApi; utm: UTMParamsType },
  { rejectValue: ApiError }
>(
  "client/me",
  async (
    { api, utm }: { api: ClientApi; utm: UTMParamsType },
    { rejectWithValue }
  ) => {
    try {
      return await api.me(utm);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateProfile = createAsyncThunk<
  Profile,
  { api: ClientApi; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  "client/updateProfile",
  async (
    { api, data }: { api: ClientApi; data: FormData },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateProfile(data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === "pending") return false;
    },
  }
);

export const fetchContact = createAsyncThunk<
  Contact,
  { api: ClientApi },
  { rejectValue: ApiError; state: RootState }
>(
  "client/fetchContact",
  async ({ api }: { api: ClientApi }, { rejectWithValue }) => {
    try {
      return await api.fetchContact();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === "pending") return false;
    },
  }
);

export const updateContactById = createAsyncThunk<
  Contact,
  { api: ClientApi; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  "client/updateContactById",
  async (
    { api, data }: { api: ClientApi; data: FormData },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateContactById(data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === "pending") return false;
    },
  }
);

export const updateContact = createAsyncThunk<
  Contact,
  { api: ClientApi; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  "client/updateContact",
  async (
    { api, data }: { api: ClientApi; data: FormData },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateContact(data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === "pending") return false;
    },
  }
);

export const fetchCompany = createAsyncThunk<
  Company,
  { api: ClientApi },
  { rejectValue: ApiError; state: RootState }
>(
  "client/fetchCompany",
  async ({ api }: { api: ClientApi }, { rejectWithValue }) => {
    try {
      return await api.fetchCompany();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === "pending") return false;
    },
  }
);

export const updateCompany = createAsyncThunk<
  Company,
  { api: ClientApi; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  "client/updateCompany",
  async (
    { api, data }: { api: ClientApi; data: FormData },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateCompany(data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === "pending") return false;
    },
  }
);

export const updateLogo = createAsyncThunk<
  Profile,
  { api: ClientApi; companyId: string; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  "client/updateLogo",
  async (
    {
      api,
      companyId,
      data,
    }: { api: ClientApi; companyId: string; data: FormData },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateLogo(companyId, data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteLogo = createAsyncThunk<
  Profile,
  { api: ClientApi; companyId: string },
  { rejectValue: ApiError; state: RootState }
>(
  "client/deleteLogo",
  async (
    { api, companyId }: { api: ClientApi; companyId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.deleteLogo(companyId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCompanyUsers = createAsyncThunk<
  Contact[],
  { api: ClientApi },
  { rejectValue: ApiError }
>(
  "admin/fetchCompanyUsers",
  async ({ api }: { api: ClientApi }, { rejectWithValue }) => {
    try {
      return await api.fetchCompanyUsers();
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteCompanyUser = createAsyncThunk<
  string,
  { api: ClientApi; contactID: string },
  { rejectValue: ApiError }
>(
  "admin/deleteCompanyUser",
  async (
    { api, contactID }: { api: ClientApi; contactID: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.deleteCompanyUser(contactID);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const upsertCompanyUser = createAsyncThunk<
  Contact,
  { api: ClientApi; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  "client/upsertCompanyUser",
  async (
    { api, data }: { api: ClientApi; data: FormData },
    { rejectWithValue }
  ) => {
    try {
      return await api.upsertCompanyUser(data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === "pending") return false;
    },
  }
);

export const sendInvitationEmail = createAsyncThunk<
  Contact,
  { api: ClientApi; email: string },
  { rejectValue: ApiError; state: RootState }
>(
  "client/sendInvitationEmail",
  async (
    { api, email }: { api: ClientApi; email: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.sendInvitationEmail(email);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getContactByEmail = createAsyncThunk<
  Contact,
  { api: ClientApi; email: string },
  { rejectValue: ApiError; state: RootState }
>(
  "client/getContactByEmail",
  async (
    { api, email }: { api: ClientApi; email: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.getContactByEmail(email);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export const onboard = createAsyncThunk<
  ClientOnBoardResponse,
  { api: ClientApi; data: ClientOnboardRequest },
  { rejectValue: ApiError }
>(
  "client/onboard",
  async (
    { api, data }: { api: ClientApi; data: ClientOnboardRequest },
    { rejectWithValue }
  ) => {
    try {
      return await api.onboard(data);
    } catch (err: any) {
      showErrorNotification(err.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(me.pending, (state) => {
      state.status.hello = "pending";
    });
    builder.addCase(me.fulfilled, (state, { payload }) => {
      state.profile = payload;
      state.status.hello = "idle";
    });
    builder.addCase(me.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.hello = "idle";
    });
    builder.addCase(updateProfile.pending, (state) => {
      state.status.general = "pending";
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.profile = payload;
      state.status.general = "idle";
    });
    builder.addCase(updateProfile.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = "idle";
      state.status.general = "idle";
    });
    builder.addCase(fetchCompany.pending, (state) => {
      state.status.general = "pending";
    });
    builder.addCase(fetchCompany.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.status.general = "idle";
    });
    builder.addCase(fetchCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = "idle";
    });
    builder.addCase(updateCompany.pending, (state) => {
      state.status.general = "pending";
    });
    builder.addCase(updateCompany.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.status.general = "idle";
    });
    builder.addCase(updateCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = "idle";
      state.status.general = "idle";
    });
    builder.addCase(fetchContact.pending, (state) => {
      state.status.general = "pending";
    });
    builder.addCase(fetchContact.fulfilled, (state, { payload }) => {
      state.contact = payload;
      state.status.general = "idle";
    });
    builder.addCase(fetchContact.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = "idle";
      state.status.general = "idle";
    });
    builder.addCase(updateContactById.pending, (state) => {
      state.status.general = "pending";
    });
    builder.addCase(updateContactById.fulfilled, (state, { payload }) => {
      state.users = usersAdapter.setOne(state.users, payload);
      state.status.general = "idle";
    });
    builder.addCase(updateContactById.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = "idle";
      state.status.general = "idle";
    });
    builder.addCase(updateContact.pending, (state) => {
      state.status.general = "pending";
    });
    builder.addCase(updateContact.fulfilled, (state, { payload }) => {
      state.contact = payload;
      state.profile.contact_preferred_language =
        payload.preferred_language || SingleLanguage.English;
      state.status.general = "idle";
    });
    builder.addCase(updateContact.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = "idle";
      state.status.general = "idle";
    });
    builder.addCase(updateLogo.pending, (state) => {
      state.status.general = "pending";
    });
    builder.addCase(updateLogo.fulfilled, (state, { payload }) => {
      state.profile = payload;
      state.status.general = "idle";
    });
    builder.addCase(updateLogo.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = "idle";
      state.status.general = "idle";
    });
    builder.addCase(deleteLogo.pending, (state) => {
      state.status.general = "pending";
    });
    builder.addCase(deleteLogo.fulfilled, (state, { payload }) => {
      state.profile = payload;
      state.status.general = "idle";
    });
    builder.addCase(deleteLogo.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = "idle";
      state.status.general = "idle";
    });
    builder.addCase(fetchCompanyUsers.pending, (state) => {
      state.status.general = "pending";
    });
    builder.addCase(fetchCompanyUsers.fulfilled, (state, { payload }) => {
      usersAdapter.setAll(state.users, payload);
      state.status.general = "idle";
    });
    builder.addCase(fetchCompanyUsers.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = "idle";
    });
    builder.addCase(upsertCompanyUser.pending, (state) => {
      state.status.general = "pending";
    });
    builder.addCase(upsertCompanyUser.fulfilled, (state, { payload }) => {
      if (state.contact.contact_id === payload.contact_id) {
        state.contact = payload;
      }
      usersAdapter.upsertOne(state.users, payload);
      state.status.general = "idle";
    });
    builder.addCase(upsertCompanyUser.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = "idle";
    });

    builder.addCase(deleteCompanyUser.pending, (state) => {
      state.status.general = "pending";
    });
    builder.addCase(deleteCompanyUser.fulfilled, (state, { payload }) => {
      usersAdapter.removeOne(state.users, payload);
      state.status.general = "idle";
    });
    builder.addCase(deleteCompanyUser.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = "idle";
    });
    builder.addCase(onboard.pending, (state) => {
      state.status.general = "pending";
    });
    builder.addCase(onboard.fulfilled, (state, { payload }) => {
      state.profile.onboarded_at = payload.onboarded_at;
      state.profile.company_name = payload.company_name;
      state.status.general = "idle";
    });
    builder.addCase(onboard.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = "idle";
    });
  },
});

export const selectCompany = (state: RootState) => state.profile.company;
export const selectContact = (state: RootState) => state.profile.contact;

export const selectProfileError = (state: RootState) => state.profile.error;

export const selectProfile = (state: RootState) => state.profile.profile;

export const selectProfileLoading = (
  state: RootState,
  type?: "general" | "assets" | "hello"
) => state.profile.status[type ?? "general"] === "pending";

export const {
  selectAll: selectCompanyUsers,
  selectById: SelectCompanyUserById,
} = usersAdapter.getSelectors((state: RootState) => state.profile.users);

export const profileReducer = profileSlice.reducer;

