import ProjectDescription from "@hellodarwin/core/lib/components/project/single/project-content/project-description";
import ProjectSidebar, {
  ProjectSidebarField,
} from "@hellodarwin/core/lib/components/project/single/project-content/project-sidebar";
import ProjectSideBarCta from "@hellodarwin/core/lib/components/project/single/project-content/project-sidebar-cta";
import { Project } from "@hellodarwin/core/lib/features/entities";
import { dollarFormat } from "@hellodarwin/core/lib/features/helpers";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import { useMemo } from "react";

import Div from "@hellodarwin/core/lib/components/common/div";
import HdTag from "@hellodarwin/core/lib/components/common/hd-tag";
import useMediaQuery from "@hellodarwin/core/lib/features/helpers/use-media-query";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import Budget from "@hellodarwin/icons/dist/icons/Budget";
import Employees from "@hellodarwin/icons/dist/icons/Employees";
import Industry from "@hellodarwin/icons/dist/icons/Industry";
import ProjectTags from "@hellodarwin/icons/dist/icons/ProjectTags";
import Service from "@hellodarwin/icons/dist/icons/Service";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import { useNavigate } from "react-router-dom";

interface ProjectDetailsProps {
  project: Project;
}

const ProjectDetails = ({ project }: ProjectDetailsProps) => {
  const { t } = useTranslation(["project-single"]);
  const { selectedLocale } = useLocale();
  const navigate = useNavigate();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  const sidebarSections = useMemo<ProjectSidebarField[][]>(() => {
    const sections: ProjectSidebarField[][] = [];

    const section1: ProjectSidebarField[] = [];
    const section2: ProjectSidebarField[] = [];
    const section3: ProjectSidebarField[] = [];

    if (project.service)
      section1.push({
        label: t("project-single|sidebar.labels.service"),
        Icon: Service,
        value: project.service,
      });
    section1.push({
      label: t("project-single|sidebar.labels.budget"),
      Icon: Budget,
      value: t("project-single|sidebar.values.budget", {
        budgetMin: dollarFormat(project.budget_min || 0, selectedLocale),
        budgetMax: dollarFormat(project.budget_max || 0, selectedLocale),
      }),
    });
    if (!!project?.start_when) {
      section1.push({
        label: t("project-single|sidebar.labels.timeline"),
        Icon: Timeline,
        value:
          t(
            `project-single|sidebar.values.startWhen.${project?.start_when?.toLowerCase()}`
          ) || "",
      });
    }
    if (!!project?.industry) {
      section1.push({
        label: t("project-single|sidebar.labels.industry"),
        Icon: Industry,
        value: project.industry,
      });
    }
    if (!!project?.provider_max) {
      section2.push({
        label: t("project-single|sidebar.labels.candidatesExpected"),
        Icon: Employees,
        value: `${project.provider_max}`,
      });
    }
    if (!!project?.tags) {
      section3.push({
        label: t("project-single|sidebar.labels.tags"),
        Icon: ProjectTags,
        value: (
          <Div wrap="wrap" flex="row" gap={6}>
            {project.tags?.map((tag, i) => (
              <HdTag text={`${tag}`} translateTag key={`tag-${i}`} />
            ))}
          </Div>
        ),
      });
    }

    if (!!section1?.length) sections.push(section1);
    if (!!section2?.length) sections.push(section2);
    if (!!section3?.length) sections.push(section3);

    return sections;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTablet]);

  return (
    <Div
      flex="row"
      justify={"stretch"}
      tablet={{ flex: "column", justify: "flex-start" }}
      gap={24}
    >
      <Div
        flex="column"
        gap={16}
        fitContent
        style={{
          width: isTablet ? "auto" : 255,
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        <ProjectSidebar sections={sidebarSections} />
        {!isTablet && (
          <ProjectSideBarCta project={project} navigate={navigate} />
        )}
      </Div>
      <ProjectDescription project={project} />
      {isTablet && <ProjectSideBarCta project={project} navigate={navigate} />}
    </Div>
  );
};

export default ProjectDetails;

